/*----Item-Card---*/

.item-card {
	.cardtitle {
		span {
			display: block;
			font-size: .75rem;
		}
		a {
			color: #1f252d;
			font-weight: 500;
			text-decoration: none;
		}
	}
	.cardprice {
		position: absolute;
		top: 30px;
		right: 15px;
		span {
			&.type--strikethrough {
				opacity: .7;
				text-decoration: line-through;
			}
			display: block;
			color: #1f252d;
		}
	}
	.cardbody {
		position: relative;
		padding: 30px 15px !important;
		border-radius: 0 0 .2rem .2rem;
	}
	.item-card-desc:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(40, 27, 45, 0.5);
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		z-index: 1;
	}
	overflow: hidden;
	border-radius: 8px;
	.item-card-btn {
		padding: 0;
		margin: 0;
		opacity: 0;
		left: 34%;
		text-align: center;
		position: absolute;
		bottom: -30px;
		z-index: 1;
		transition: all .5s ease 0s;
	}
	&:hover {
		.item-card-btn {
			opacity: 1;
			bottom: 25%;
			&.item-card-btn1 {
				opacity: 1;
				bottom: 35%;
			}
		}
		.data1 .item-card-img img {
			transform: scale(1);
		}
	}
	.item-card-btn a {
		display: block;
		position: relative;
		transition: all .3s ease-in-out;
		&:after,
		&:before {
			transform: translateX(-50%);
			position: absolute;
			left: 50%;
			top: -30px;
		}
		&:after {
			content: '';
			height: 15px;
			width: 15px;
			border-radius: 0;
			transform: translateX(-50%) rotate(45deg);
			top: -20px;
			z-index: -1;
		}
	}
	.product-info {
		padding: 0;
		margin: 0;
		opacity: 0;
		left: 34%;
		text-align: center;
		position: absolute;
		bottom: -30px;
		z-index: 1;
		transition: all .5s ease 0s;
	}
	&:hover .product-info {
		opacity: 1;
		bottom: 30%;
	}
}

.item-card7-imgs .bdir {
	position: absolute;
	top: 12px;
	left: 12px;
	padding: 3px 7px;
	z-index: 2;
}

.item-card7-overlaytext {
	position: relative;
	a {
		span {
			padding: 4px 10px;
			display: inline-block;
		}
		position: absolute;
		bottom: 12px;
		left: 12px;
		z-index: 2;
	}
	h4 {
		position: absolute;
		bottom: 12px;
		right: 12px;
		font-size: 25px;
		color: $white;
		z-index: 2;
	}
}

.item-card7-imgs {
	img {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
	&:hover img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
	position: relative;
	overflow: hidden;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(40, 27, 45, 0.3);
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		z-index: 1;
	}
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		i {
			margin: 0 auto;
			top: 35%;
			font-size: 40px;
			text-align: center;
			left: 0;
			right: 0;
			display: block;
			color: $white;
			position: absolute;
		}
	}
}

.item-card9-img .item-card9-imgs a {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	i {
		margin: 0 auto;
		top: 35%;
		font-size: 40px;
		text-align: center;
		left: 0;
		right: 0;
		display: block;
		color: $white;
		position: absolute;
	}
}

.item-card7-desc ul li {
	margin-right: 1rem;
	font-size: 13px;
	&:last-child {
		margin-right: 0;
	}
}


/*--inputs incremet&decrement--*/

.input-indec {
	.quantity-left-minus.btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: 0;
	}
	.quantity-right-plus.btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 0;
	}
}


/*--item-list--*/

.item-list .list-group-item {
	border: 0;
	margin-bottom: 0;
	border-bottom: 1px solid $border;
	i {
		margin-right: 5px;
		width: 25px;
		height: 25px;
		border-radius: 2px;
		font-size: 12px;
		text-align: center;
		line-height: 25px;
		color: $white !important;
	}
}


/*--item-user--*/

.item-user .item-user-icons a {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	margin-top: 0.3rem;
	&:hover {
		color: $white;
	}
}

.item1-links a {
	padding: 0.75rem 1.25rem;
	font-weight: 400;
	font-size: 0.875rem;
	color: $color;
	&.active {
		font-weight: 400;
		font-size: 0.875rem;
	}
}

.item-card9-desc {
	font-size: 15px;
}

.item-card9 {
	color: #616267;
}


/*-- item1-card --*/

.item1-card-img img {
	border: 1px solid $border;
	margin: 0 auto;
	padding: 5px;
}

.item1-card .item1-card-info .item1-card-icons li {
	display: inline-block;
	margin-right: 15px;
	a {
		color: #8c98b9;
	}
}


/*-- item1-card-tabs --*/

.item1-card-tab {
	text-align: center;
	.tab-content .tab-pane {
		border: 1px solid $border;
	}
}

.item1-tab-heading {
	padding: 15px;
	border: 1px solid $border;
	text-align: center;
	border-radius: 50px;
	margin: 0 auto;
	display: inline-table;
}

.item1-tabs-menu ul li {
	a {
		padding: 10px 20px 11px 20px;
	}
	.active {
		border-radius: 25px;
	}
}

.item1-card-tab .tab-content {
	padding: 15px;
	text-align: initial;
}

.item-video {
	a {
		display: inline-block;
		width: 2rem;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2rem;
		background: $white;
		color: #212529;
		font-size: 25px;
		align-items: center;
		margin: 0 auto;
		justify-content: center;
	}
	i {
		margin-left: 5px;
	}
}


/*-- item2-card --*/

.item2-card .item2-card-img {
	border: 1px solid $border;
	padding: 5px;
	img {
		width: 200px;
		height: 100px;
		text-align: center;
		margin: 0 auto;
	}
}

.item2-card-info {
	padding-top: 15px;
}

.item2-btn {
	padding-top: 40px;
}


/*-- media-list --*/

.media-list .media-icons {
	font-size: 18px;
	color: $black;
}


/*-- item3-medias --*/

.item3-lists {
	list-style: none;
	padding-left: 0;
}

.item3-medias .item3-lists li {
	a {
		padding: 5px 4px 5px 0px;
		font-size: 12px;
	}
	i {
		margin-right: 5px;
	}
	margin-right: 5px;
}

.item-card2-desc ul li {
	margin-right: 1.5rem;
	font-size: 13px;
	&:last-child {
		margin-right: 0;
	}
}

.item7-card-desc a:first-child {
	margin-right: 1rem;
}

.item7-card-img {
	position: relative;
	overflow: hidden;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.item-cards7-ic {
	display: inline-block;
	&.realestate-list li {
		a {
			i {
				width: 1rem;
			}
			color: #3d4e67;
		}
		width: 50%;
	}
	li {
		float: left;
		width: 50%;
		margin-right: 0 !important;
		margin-bottom: 0.5rem;
	}
}

.item7-card-text span {
	position: absolute;
	bottom: 17px;
	left: 20px;
}


/*--home2 category effect--*/

.item-card-img {
	overflow: hidden;
}

.item-card .item-card-desc {
	img {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
		width: 100%;
	}
	&:hover img {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
}
.item-card9-imgs img {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.item-card9 p.leading-tight,
.item-card2 p.leading-tight {
	display: block;
	display: -webkit-box;
	max-width: 100%;
	margin: 0 auto;
	font-size: 14px;
	line-height: 1;
	-webkit-line-clamp: 2;
	-moz-line-clamp: 2;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

#tab-11 .item-card2-img:hover a,
#tab-12 .item-card2-img:hover a {
	background: none;
}

.item-card9-imgs:hover img {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.item-card-desc {
	position: relative;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
	}
}

.item-card .item-card-desc .item-card-text {
	position: absolute;
	top: 42%;
	left: 0;
	right: 0;
	text-align: center;
	color: $white;
	z-index: 2;
}

.item-card-text {
	span {
		font-size: 16px;
		display: block;
		margin: 0.5rem;
		font-weight: 400;
	}
	h4 {
		font-size: 25px;
		font-weight: 300;
	}
}

.item-card2-img {
	position: relative;
	overflow: hidden;
	img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
	&:hover img {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.item-card2-icons {
	.item-card2-icons-r {
		position: absolute;
		top: 20px;
		right: 20px;
		display: inline-block;
		width: 2rem;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2rem;
		border-radius: 50px;
		background: $pink;
		color: $white;
	}
	.item-card2-icons-l {
		position: absolute;
		top: 20px;
		right: 60px;
		display: inline-block;
		width: 2rem;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2rem;
		border-radius: 50px;
		background: $purple;
		color: $white;
	}
}


/*end*/


/*--item-card3--*/

.item-card3-img img {
	width: 100%;
	height: 100%;
	max-height: 200px;
	margin: 0 auto;
	position: relative;
	margin-bottom: -35px;
}

.item-card3-desc {
	img {
		border: 3px solid $white;
	}
	h4 {
		position: relative;
		top: 5px;
	}
}


/*end*/


/*--item-card4--*/

.item-card4-img a {
	position: absolute;
	top: 35px;
	left: 30px;
}


/*end*/


/*--item-card5--*/

.item-card5-img a {
	position: absolute;
	right: 12px;
	top: 15px;
}

.item-card5-icon a {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	border-radius: 50px;
	background: $white;
	color: #212529;
}


/*end*/


/*---itme-card6--*/

.item-card6-img {
	img {
		width: 100%;
		height: 100%;
		max-height: 230px;
		margin: 0 auto;
	}
	a {
		position: absolute;
		right: 12px;
		top: 15px;
		background: $black-7;
		color: $white;
		padding: 0.3rem 0.5rem;
	}
}

.item-card6-footer .item-card6-body {
	padding: 10px;
}


/*end*/


/*---itme-card7--*/

.item-card7-img {
	position: relative;
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: $black-1;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		z-index: 9;
	}
	a {
		position: absolute;
		bottom: 20px;
		left: 15px;
	}
	h4 {
		position: absolute;
		bottom: 20px;
		right: 15px;
		font-size: 25px;
		color: $white;
	}
}


/*end*/


/*-- item-card9-img --*/

.item-card9-img {
	position: relative;
}

.item-card9-icons {
	position: absolute;
	top: 20px;
	right: 15px;
	z-index: 9;
	a {
		width: 2rem;
		display: inline-block;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2rem;
		border-radius: 50px;
		color: $white;
	}
}

.item-card9-cost h3 {
	margin-top: 5px;
}

.item-card9-imgs {
	position: relative;
	overflow: hidden;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.item-card9-desc ul {
	display: inline-flex;
	margin-bottom: 0;
	li {
		width: 50%;
		float: left;
	}
}


/*end*/


/*-- items-gallery --*/

.items-gallery .items-blog-tab-heading {
	text-align: center;
	border-radius: 50px;
	margin: 0 auto;
	display: inline-table;
	padding: 15px;
}

.items-blog-tab-heading .items-blog-tab-menu li a {
	padding: 10px 20px 11px 20px;
	color: $color;
	background: #eeeefb;
	margin: 0 10px;
	border-radius: 3px;
	&:hover {
		text-decoration: none;
	}
}

.tabs-menu ul li a:hover {
	text-decoration: none;
}

.items-gallery .tab-content {
	padding: 15px;
	text-align: initial;
}


/*end*/


/*-- item2-gl --*/

.item2-gl .item2-gl-nav select {
	width: 20%;
}

.item2-gl-nav {
	padding: 15px;
	border: 1px solid $border;
	text-align: right;
	border-radius: 3px;
	.item2-gl-menu {
		margin-right: 10px;
		li a {
			padding: 10px 10px 11px 10px;
			color: $color;
			font-size: 20px;
		}
	}
}

.item2-gl .tab-content {
	padding-top: 1.5rem;
	text-align: initial;
}

.item-all-cat {
	.item-all-card {
		position: relative;
		padding: 1.5rem 1.5rem;
		border-radius: 4px;
		margin-bottom: 10px;
		color: $white;
		border: 1px solid $border;
	}
	.category-type .item-all-card img {
		width: 7rem;
		height: 7rem;
		border-radius: 50%;
		padding: 2.3rem 0;
	}
	.row .item-all-card {
		margin-bottom: 1.5rem;
	}
	.item-all-card a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.item-all-card a:hover .item-all-text h5 {
		color: $color !important;
	}
	.category-type .item-all-card:hover {
		background: #eceef4;
	}
}

.iteam-all-icon i {
	font-size: 1.5rem;
}

.item-all-card img.imag-service {
	width: 40%;
}
/*end*/


/*--item-box--*/

.item-box {
	.item-box-wrap {
		margin-top: 15px;
	}
	.stamp i {
		font-size: 18px;
		font-size: 25px;
		line-height: 2;
	}
	.item-box-wrap h5 {
		font-size: 18px;
		font-weight: 600;
	}
}
.item-search-menu ul li {
	.active {
		background: rgba(13, 7, 62, 0.4);
		color: $white;
		border-radius: 3px 3px 0 0;
	}
	a {
		padding: 8px 15px;
		color: $white;
		text-align: center;
		letter-spacing: 0.5px;
		background: transparent;
		margin: 0 5px;
		border-radius: 3px 3px 0 0;
	}
}

.item-search-tabs {
	margin-top: 2rem;
	.item-search-menu ul {
		padding: 5px 15px;
	}
	.tab-content {
		border: 1px solid rgba(4, 4, 44, 0.2);
		background: rgba(4, 16, 58, 0.4);
		padding: 15px;
		margin: 1px 5px 0 0;
		border-radius: 2px;
	}
	&.travel-content {
		.select2-container {
			width: 100% !important;
		}
		.form-control {
			border: 1px solid $border;
		}
	}
	.form-control {
		&.border {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	a.btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		font-size: 0.8525rem;
		right: 0;
		position: relative;
		line-height: 1.99;
	}
}