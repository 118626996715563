/*----- Custom-Control ------*/

.custom-control-inline {
	display: -ms-inline-flexbox;
	display: inline-flex !important;
	margin-right: 1rem;
}

.custom-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	&:active~.custom-control-label::before {
		color: $white;
		background-color: #d4e1f4;
	}
	&:disabled~.custom-control-label {
		color: #aaadb2;
		&::before {
			background-color: #eff2f7;
		}
	}
}

.custom-control-label {
	vertical-align: middle;
	margin-bottom: 0;
	position: initial;
	margin-top: 3px;
	&:before {
		border: 1px solid rgba(167, 180, 201, 0.5);
		background-color: $white;
		background-size: .5rem;
	}
	&::before {
		position: absolute;
		top: 0.25rem;
		left: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		pointer-events: none;
		content: "";
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	&::after {
		position: absolute;
		top: 0.25rem;
		left: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		content: "";
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 50% 50%;
	}
}

.custom-checkbox .custom-control-label::before {
	border-radius: 3px;
}

.br.custom-checkbox .custom-control-label::before {
	border-radius: 50px;
}

.custom-checkbox .custom-control-input {
	&:checked~.custom-control-label::after {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
	}
	&:indeterminate~.custom-control-label::after {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
	}
	&:disabled {
		&:checked~.custom-control-label::before {
			background-color: #e8eaef;
			border-color:#e8eaef;
		}
		&:indeterminate~.custom-control-label::before {
			background-color: rgba(5, 117, 230, 0.9);
		}
	}
}

.custom-radio {
	.custom-control-label::before {
		border-radius: 50%;
	}
	.custom-control-input {
		&:checked~.custom-control-label::after {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
		}
		&:disabled:checked~.custom-control-label::before {
			background-color: #e8eaef;
			border-color:#e8eaef;
		}
	}
}

.custom-select {
	display: inline-block;
	width: 100%;
	height: 2.375rem;
	padding: 0.5rem 1.75rem 0.5rem 0.75rem;
	line-height: 1.5;
	color: #8c98b9;
	vertical-align: middle;
	background-size: 8px 10px;
	border: 1px solid $border;
	border-radius: 3px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&:focus {
		outline: 0;
		box-shadow: none;
		border: 1px solid $border !important;
		&::-ms-value {
			color: #3d4e67;
			background-color: $white;
		}
	}
	&[multiple],
	&[size]:not([size="1"]) {
		height: auto;
		padding-right: 0.75rem;
		background-image: none;
	}
	&:disabled {
		color: #aaadb2;
		background-color: #eff2f7;
	}
	&::-ms-expand {
		opacity: 0;
	}
}

.custom-select-sm {
	height: calc(1.8125rem + 2px);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-size: 75%;
}

div.dataTables_wrapper div.dataTables_filter input {
	margin-left: 0.5em;
	display: inline-block;
	width: auto;
	height: calc(1.8125rem + 2px);
}

.custom-select-lg {
	height: calc(2.6875rem + 2px);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-size: 125%;
}

.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 2.375rem;
	margin-bottom: 0;
}

.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: 2.375rem;
	margin: 0;
	opacity: 0;
	&:lang(en)~.custom-file-label::after {
		content: "Browse";
	}
}
.browse-file.form-control{
  background-color: transparent;
  border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: 2.375rem;
	padding: 0.375rem 0.75rem;
	line-height: 1.8;
	color: #3d4e67;
	background-color: $white;
	border: 1px solid $border;
	border-radius: 4px;
	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		display: block;
		height: calc(2.375rem - 1px * 2);
		padding: 0.375rem 0.75rem;
		line-height: 1.8;
		color: $white;
		content: "Browse";
		border-radius: 0;
	}
}

.custom-range {
	width: 100%;
	padding-left: 0;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&:focus {
		outline: none;
		box-shadow: none;
	}
	&::-webkit-slider-thumb:active {
		background-color: #d4e1f4;
	}
	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: #eff2f7;
		border-color: transparent;
		border-radius: 1rem;
		background: $black;
		content: '';
		pointer-events: none;
	}
	&::-moz-range-thumb {
		width: 1rem;
		height: 1rem;
		border: 0;
		border-radius: 1rem;
		-moz-appearance: none;
		appearance: none;
		background: $white;
		border: 1px solid rgba(0, 30, 75, 0.12);
		position: relative;
		transition: .3s border-color, .3s background-color;
		&:active {
			background-color: #d4e1f4;
		}
	}
	&::-moz-range-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: #eff2f7;
		border-color: transparent;
		border-radius: 1rem;
	}
	&::-ms-thumb {
		width: 1rem;
		height: 1rem;
		border: 0;
		border-radius: 1rem;
		appearance: none;
		&:active {
			background-color: #d4e1f4;
		}
	}
	&::-ms-fill-lower {
		background-color: #eff2f7;
		border-radius: 1rem;
		background: $black;
	}
}

.custom-control {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5rem;
}

.custom-controls-stacked .custom-control {
	margin-bottom: .25rem;
}
.custom-control-description {
	line-height: 1.5rem;
}

.custom-switch {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	margin: 0;
	padding-left: inherit;
}

.custom-switch-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.custom-switches-stacked {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}

.custom-switch-input:focus~.custom-switch-indicator {
	box-shadow: none;
}

.custom-switch-description {
	margin-left: .5rem;
	color: #6e7687;
	transition: .3s color;
}