/*----- Media ------*/

.media {
	margin-top: 15px;
	zoom: 1;
	overflow: hidden;
	img {
		width: 45px;
		height: 45px;
	}
	&.meida-md img {
		width: 65px;
		height: 65px;
	}
	&.meida-lg img {
		width: 80px;
		height: 80px;
	}
	display: flex;
	align-items: flex-start;
	-ms-flex-align: start;
	&.media-lg img {
		border-radius: 3px;
	}
	.chat {
		i,
		a {
			font-size: 13px;
		}
	}
}

.media-object {
	display: block;
}

.media-right,
.media>.pull-right {
	padding-left: 10px;
}

.media-body,
.media-left,
.media-right {
	display: table-cell;
	vertical-align: top;
}

.media-left,
.media>.pull-left {
	padding-right: 10px;
}

.media-heading {
	margin-top: 0;
	margin-bottom: 5px;
}

.media-body h4 {
	font-weight: 500;
	font-size: 15px;
}

.media-middle {
	vertical-align: middle;
}

.media-bottom {
	vertical-align: bottom;
}

.media-body {
	-ms-flex: 1;
	flex: 1;
	zoom: 1;
	overflow: hidden;
}

.list-media {
	.media-img {
		position: relative;
		float: left;
		width: 48px;
		margin-right: 20px;
	}
	.info {
		padding-left: 55px;
		min-height: 40px;
		height: auto;
		position: relative;
		h4 a {
			font-size: 16px;
			margin-top: 10px;
		}
		p {
			font-size: 13px;
		}
		.text-right {
			right: 0;
			color: #8a8a8a;
			top: 50%;
			font-size: 12px;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			margin-top: -28px;
		}
	}
}

.media-icon {
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	border-radius: 100%;
}

.media-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.mediaicon {
	font-size: 18px;
	color: $white;
	width: 40px;
	height: 40px;
	text-align: center;
	padding: 5px 0px;
	border-radius: 20px;
}

.mediafont {
	font-size: 16px;
	font-weight: 600;
}

.media-posts {
	width: 100%;
	height: 233px;
	overflow: hidden;
	&:hover {
		overflow: scroll;
	}
}
