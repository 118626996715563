/*----- Footer ------*/

.footer {
	background: $white;
	border-top: 1px solid $border;
	font-size: 0.875rem;
	padding: 1.25rem 0;
	color: #8c98b9;
	.social {
		ul li {
			float: left;
			padding: 7px;
			a {
				color: #8c98b9;
			}
		}
		margin: 0 auto;
	}
	a:not(.btn) {
		color: #1f252d;
	}
}

@media print {
	.footer {
		display: none;
	}
}

.footer .border-top {
	border-top: 1px solid rgba(167, 180, 201, 0.1) !important;
}

.footer-main {
	padding-top: 0.50rem;
	padding-bottom: 0.50rem;
	background: transparent;
	margin-top:auto;
	.form-control {
		border: 0 !important;
	}
	.social li {
		float: left;
		margin-right: 15px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: #8c98b9;
			line-height: 0;
		}
	}
	.payments li {
		float: left;
		margin-right: 15px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: $white-6 ;
			line-height: 0;
		}
	}
	h6 {
		font-size: 18px;
		margin-bottom: 1.2rem;
	}
	p {
		color: $white-8;
	}
	a {
		line-height: 2;
		transition: opacity .2s;
		color: $white-6 ;
		font-size: 14px;
	}
	&.footer-main1 {
		a {
			color: $color;
		}
		.form-control {
			border: 1px solid $border !important;
		}
	}
}

footer .border-bottom {
	border-bottom: 1px solid rgba(167, 180, 201, 0.1) !important;
}

.footer-links {
	a {
		color: $white-8;
		display: inline-block;
	}
	.btn.btn-link {
		background: #272b44;
		box-shadow: 0px 2px 5px 2px rgb(50, 55, 84);
		&:hover {
			background: #272b44;
			box-shadow: 0px 2px 5px 2px rgb(50, 55, 84);
			color: $white;
		}
	}
}

.footerimg img {
	width: 35px;
	height: 35px;
}