/*---- Ratings ----*/

.rating-stars {
	.rating-stars-container {
		font-size: 0px;
		.rating-star {
			display: inline-block;
			font-size: 32px;
			cursor: pointer;
			padding: 5px 8px;
			color: #edeff5;
			&.sm {
				display: inline-block;
				font-size: 14px;
				color: #FFC107;
				cursor: pointer;
				padding: 1px;
			}
			&.is--no-hover,
			.fa-heart .is--no-hover {
				color: #3e4b5b;
			}
		}
	}
	input {
		display: none;
		margin: 0 auto;
		text-align: center;
		padding: 0.375rem 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.6;
		color: #3d4e67;
		background-color: $white;
		background-clip: padding-box;
		border: 1px solid $border;
		border-radius: 3px;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	&.star input {
		display: none;
	}
}/*-----Br Themes-----*/

.br-theme-bars-horizontal .br-widget {
	a {
		background-color: #edeff5;
		&.br-active,
		&.br-selected {
			background-color: $primary;
		}
	}
	.br-current-rating {
		color: $primary;
	}
}

.br-theme-bars-pill .br-widget a {
	background-color: #edeff5;
	color: $primary;
	&.br-active,
	&.br-selected {
		background-color: $primary;
		color: white;
	}
}

.br-theme-bars-square .br-widget a {
	&.br-active,
	&.br-selected {
		border: 2px solid $border;
		color: $primary;
	}
	border: 2px solid rgba(107,
	122,
	144,
	0.1);
	background-color: $white;
	color: #95a1b2;
}

.br-theme-bars-movie .br-widget {
	a {
		&.br-active,
		&.br-selected {
			background-color: $primary;
		}
		background-color: #edeff5;
	}
	.br-current-rating {
		color: $primary;
	}
}

.br-theme-bars-1to10 .br-widget {
	a {
		background-color: #edeff5;
		&.br-active,
		&.br-selected {
		    background-color: $primary;
		}
	}
	.br-current-rating {
		color: $primary;
	}
}

.br-theme-fontawesome-stars .br-widget a {
	&.br-active:after,
	&.br-selected:after {
		color: $warning;
	}
}
.star-ratings.sm .br-theme-fontawesome-stars .br-widget a{
	font-size:14px;
}
.br-theme-fontawesome-stars-o .br-widget a {
	&.br-active:after,
	&.br-selected:after {
		content: '\f005';
		color: $warning;
	}
	&.br-fractional:after {
		content: '\f123';
		color: $warning;
	}
}

.br-theme-fontawesome-stars .br-widget a:after {
	content: '\f005';
	color: #edeff5;
}

.br-theme-fontawesome-stars-o .br-widget a:after {
	content: '\f006';
	color: #edeff5;
}


/*-----Br Themes-----*/