/*----- Loaders ------*/

.spinner {
	background: $primary;
}

.spinner-lg {
	background-color: $primary;
}

.double-bounce1,
.double-bounce2,
.cube1,
.cube2 {
	background: $primary;
}

.lds-heart div {
	background: $primary;
	&:after,
	&:before {
		background: $primary;
	}
}

.lds-ring div {
	border: 6px solid $primary;
	border-color: $primary transparent transparent transparent;
}

.lds-hourglass:after {
	border: 26px solid $primary;
	border-color: $primary transparent;
}

.lds-circle>div {
	background: $primary;
}

.lds-dual-ring:after {
	border: 5px solid $primary;
	border-color: $primary transparent $primary transparent;
}

.lds-facebook div,
.lds-roller div:after {
	background: $primary;
}

.lds-ripple div {
	border: 4px solid $primary;
}

.lds-spinner div:after,
.lds-default div,
.lds-ellipsis div,
.lds-grid div,
div.lds-ball2 div,
.lds-ball div {
	background: $primary;
}

.lds-bars div {
	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(4) {
		background: $primary;
	}
}

@keyframes lds-blocks {
	0% {
		background: $secondary;
	}
	12.5% {
		background: $secondary;
	}
	12.625% {
		background: $primary;
	}
	100% {
		background: $primary;
	}
}

@-webkit-keyframes lds-blocks {
	0% {
		background: $secondary;
	}
	12.5% {
		background: $secondary;
	}
	12.625% {
		background: $primary;
	}
	100% {
		background: $primary;
	}
}

.lds-blocks div,
.lds-cube div {
	background: $primary;
}

.lds-eclipse div {
	box-shadow: 0 4px 0 0 $primary;
}

.lds-flickr div {
	&:nth-child(1),
	&:nth-child(3) {
		background: $primary;
	}
}

.lds-gear>div div {
	background: $primary;
}

.lds-magnify>div>div div {
	&:nth-child(1) {
		border: 12px solid $primary;
	}
	&:nth-child(2) {
		background: $primary;
	}
}

.lds-pacman>div:nth-child(2) div,
.lds-disk>div div:nth-child(1) {
	background: $primary;
}

.bn-label {
	background: $primary;
	color: $white;
}

.best-ticker {
	border: solid 1px $primary;
}


/*--loaders--*/
/*----Dimmer---*/

.dimmer {
	position: relative;
	.loader {
		display: none;
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	&.active {
		.loader {
			display: block;
		}
		.dimmer-content {
			opacity: .04;
			pointer-events: none;
		}
	}
}


/*----Dimmer---*/


/*--------loading-icon ---------*/

.loading-icon {
	position: absolute;
	vertical-align: middle;
	border-radius: 50%;
	background: transparent;
	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background: rgba(45, 220, 211, 0.2);
		animation: bounce 4s infinite;
	}
	&::after {
		content: "";
		position: absolute;
		opacity: 0;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background: rgba(45, 220, 211, 0.2);
		animation: bounce 4s 0.5s infinite;
	}
}

@keyframes bounce {
	0% {
		transform: scale(0);
		-webkit-transform: scale(0);
		opacity: 1;
	}
	90% {
		transform: scale(2);
		-webkit-transform: scale(2);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@-webkit-keyframes bounce {
	0% {
		transform: scale(0);
		-webkit-transform: scale(0);
		opacity: 1;
	}
	90% {
		transform: scale(2);
		-webkit-transform: scale(2);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}


/*--------loading-icon ---------*/


/*--------spinner---------*/

.spinner {
	width: 64px;
	height: 64px;
	margin: 0 auto;
	-webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
	animation: sk-rotateplane 1.2s infinite ease-in-out;
}

.spinner-lg {
	width: 100px;
	height: 100px;
	margin: 100px auto;
	-webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
	animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
	0% {
		-webkit-transform: perspective(120px);
	}
	50% {
		-webkit-transform: perspective(120px) rotateY(180deg);
	}
	100% {
		-webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
	}
}

@keyframes sk-rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	}
	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	}
	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}


/*--------spinner---------*/


/*--------spinner1---------*/

.spinner1 {
	width: 64px;
	height: 64px;
	position: relative;
	margin: 0 auto;
}

.spinner1-lg {
	width: 100px;
	height: 100px;
	position: relative;
	margin: 100px auto;
}

.double-bounce1 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}


/*--------spinner1---------*/


/*--------spinner2---------*/

.spinner2 {
	margin: 0 auto;
	width: 64px;
	height: 64px;
	position: relative;
}

.cube1 {
	width: 15px;
	height: 15px;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
	animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
	width: 15px;
	height: 15px;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
	animation: sk-cubemove 1.8s infinite ease-in-out;
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
	25% {
		-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	}
	50% {
		-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	}
	75% {
		-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	}
	100% {
		-webkit-transform: rotate(-360deg);
	}
}

@keyframes sk-cubemove {
	25% {
		transform: translateX(42px) rotate(-90deg) scale(0.5);
		-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	}
	50% {
		transform: translateX(42px) translateY(42px) rotate(-179deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
	}
	50.1% {
		transform: translateX(42px) translateY(42px) rotate(-180deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	}
	75% {
		transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
		-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	}
	100% {
		transform: rotate(-360deg);
		-webkit-transform: rotate(-360deg);
	}
}


/*--------spinner2---------*/


/*--------lds-heart---------*/

.lds-heart {
	position: relative;
	width: 64px;
	height: 64px;
	transform: rotate(45deg);
	transform-origin: 32px 32px;
	margin: 0 auto;
	div {
		top: 23px;
		left: 19px;
		position: absolute;
		width: 26px;
		height: 26px;
		animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
		&:after {
			content: " ";
			position: absolute;
			display: block;
			width: 26px;
			height: 26px;
		}
		&:before {
			content: " ";
			position: absolute;
			display: block;
			width: 26px;
			height: 26px;
			left: -17px;
			border-radius: 50% 0 0 50%;
		}
		&:after {
			top: -17px;
			border-radius: 50% 50% 0 0;
		}
	}
}

@keyframes lds-heart {
	0% {
		transform: scale(0.95);
	}
	5% {
		transform: scale(1.1);
	}
	39% {
		transform: scale(0.85);
	}
	45% {
		transform: scale(1);
	}
	60% {
		transform: scale(0.95);
	}
	100% {
		transform: scale(0.9);
	}
}


/*--------lds-heart---------*/


/*--------lds-ring---------*/

.lds-ring {
	position: relative;
	width: 64px;
	height: 64px;
	margin: 0 auto;
	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 51px;
		height: 51px;
		margin: 6px;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		&:nth-child(1) {
			animation-delay: -0.45s;
		}
		&:nth-child(2) {
			animation-delay: -0.3s;
		}
		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


/*--------lds-ring---------*/


/*--------lds-hourglass---------*/

.lds-hourglass {
	position: relative;
	width: 64px;
	height: 64px;
	margin: 0 auto;
	&:after {
		content: " ";
		display: block;
		border-radius: 50%;
		width: 0;
		height: 0;
		margin: 6px;
		box-sizing: border-box;
		animation: lds-hourglass 1.2s infinite;
	}
}

@keyframes lds-hourglass {
	0% {
		transform: rotate(0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	50% {
		transform: rotate(900deg);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	100% {
		transform: rotate(1800deg);
	}
}


/*--------lds-hourglass---------*/


/*--lds-circle--*/

.lds-circle {
	display: inline-block;
	transform: translateZ(1px);
	>div {
		display: inline-block;
		width: 51px;
		height: 51px;
		margin: 6px;
		border-radius: 50%;
		animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}
}

@keyframes lds-circle {
	0%,
	100% {
		animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
	}
	0% {
		transform: rotateY(0deg);
	}
	50% {
		transform: rotateY(1800deg);
		animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
	}
	100% {
		transform: rotateY(3600deg);
	}
}


/*--lds-circle--*/


/*---Ring loaders --*/

.lds-dual-ring {
	display: inline-block;
	width: 64px;
	height: 64px;
	&:after {
		content: " ";
		display: block;
		width: 46px;
		height: 46px;
		margin: 1px;
		border-radius: 50%;
		animation: lds-dual-ring 1.2s linear infinite;
	}
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


/*---Ring loaders --*/


/*-- lines loader --*/

.lds-facebook {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
	div {
		display: inline-block;
		position: absolute;
		left: 6px;
		width: 13px;
		animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
		&:nth-child(1) {
			left: 6px;
			animation-delay: -0.24s;
		}
		&:nth-child(2) {
			left: 26px;
			animation-delay: -0.12s;
		}
		&:nth-child(3) {
			left: 45px;
			animation-delay: 0;
		}
	}
}

@keyframes lds-facebook {
	0% {
		top: 6px;
		height: 51px;
	}
	50%,
	100% {
		top: 19px;
		height: 26px;
	}
}


/*-- lines loader --*/


/*---Ringdots---*/

.lds-roller {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
	div {
		animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		transform-origin: 32px 32px;
		&:after {
			content: " ";
			display: block;
			position: absolute;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			margin: -3px 0 0 -3px;
		}
		&:nth-child(1) {
			animation-delay: -0.036s;
			&:after {
				top: 50px;
				left: 50px;
			}
		}
		&:nth-child(2) {
			animation-delay: -0.072s;
			&:after {
				top: 54px;
				left: 45px;
			}
		}
		&:nth-child(3) {
			animation-delay: -0.108s;
			&:after {
				top: 57px;
				left: 39px;
			}
		}
		&:nth-child(4) {
			animation-delay: -0.144s;
			&:after {
				top: 58px;
				left: 32px;
			}
		}
		&:nth-child(5) {
			animation-delay: -0.18s;
			&:after {
				top: 57px;
				left: 25px;
			}
		}
		&:nth-child(6) {
			animation-delay: -0.216s;
			&:after {
				top: 54px;
				left: 19px;
			}
		}
		&:nth-child(7) {
			animation-delay: -0.252s;
			&:after {
				top: 50px;
				left: 14px;
			}
		}
		&:nth-child(8) {
			animation-delay: -0.288s;
			&:after {
				top: 45px;
				left: 10px;
			}
		}
	}
}

@keyframes lds-roller {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


/*---Ringdots---*/


/*---Ripple---*/

.lds-ripple {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
	div {
		position: absolute;
		opacity: 1;
		border-radius: 50%;
		animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
		&:nth-child(2) {
			animation-delay: -0.5s;
		}
	}
}

@keyframes lds-ripple {
	0% {
		top: 28px;
		left: 28px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: -1px;
		left: -1px;
		width: 58px;
		height: 58px;
		opacity: 0;
	}
}


/*---Ripple---*/


/*-- Spinner loader --*/

.lds-spinner {
	color: official;
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
	div {
		transform-origin: 32px 32px;
		animation: lds-spinner 1.2s linear infinite;
		&:after {
			content: " ";
			display: block;
			position: absolute;
			top: 3px;
			left: 29px;
			width: 5px;
			height: 14px;
			border-radius: 20%;
		}
		&:nth-child(1) {
			transform: rotate(0deg);
			animation-delay: -1.1s;
		}
		&:nth-child(2) {
			transform: rotate(30deg);
			animation-delay: -1s;
		}
		&:nth-child(3) {
			transform: rotate(60deg);
			animation-delay: -0.9s;
		}
		&:nth-child(4) {
			transform: rotate(90deg);
			animation-delay: -0.8s;
		}
		&:nth-child(5) {
			transform: rotate(120deg);
			animation-delay: -0.7s;
		}
		&:nth-child(6) {
			transform: rotate(150deg);
			animation-delay: -0.6s;
		}
		&:nth-child(7) {
			transform: rotate(180deg);
			animation-delay: -0.5s;
		}
		&:nth-child(8) {
			transform: rotate(210deg);
			animation-delay: -0.4s;
		}
		&:nth-child(9) {
			transform: rotate(240deg);
			animation-delay: -0.3s;
		}
		&:nth-child(10) {
			transform: rotate(270deg);
			animation-delay: -0.2s;
		}
		&:nth-child(11) {
			transform: rotate(300deg);
			animation-delay: -0.1s;
		}
		&:nth-child(12) {
			transform: rotate(330deg);
			animation-delay: 0s;
		}
	}
}

@keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}


/*-- Spinner loader --*/


/*-- Default loader --*/

.lds-default {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
	div {
		position: absolute;
		width: 5px;
		height: 5px;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
		&:nth-child(1) {
			animation-delay: 0s;
			top: 29px;
			left: 53px;
		}
		&:nth-child(2) {
			animation-delay: -0.1s;
			top: 18px;
			left: 50px;
		}
		&:nth-child(3) {
			animation-delay: -0.2s;
			top: 9px;
			left: 41px;
		}
		&:nth-child(4) {
			animation-delay: -0.3s;
			top: 6px;
			left: 29px;
		}
		&:nth-child(5) {
			animation-delay: -0.4s;
			top: 9px;
			left: 18px;
		}
		&:nth-child(6) {
			animation-delay: -0.5s;
			top: 18px;
			left: 9px;
		}
		&:nth-child(7) {
			animation-delay: -0.6s;
			top: 29px;
			left: 6px;
		}
		&:nth-child(8) {
			animation-delay: -0.7s;
			top: 41px;
			left: 9px;
		}
		&:nth-child(9) {
			animation-delay: -0.8s;
			top: 50px;
			left: 18px;
		}
		&:nth-child(10) {
			animation-delay: -0.9s;
			top: 53px;
			left: 29px;
		}
		&:nth-child(11) {
			animation-delay: -1s;
			top: 50px;
			left: 41px;
		}
		&:nth-child(12) {
			animation-delay: -1.1s;
			top: 41px;
			left: 50px;
		}
	}
}

@keyframes lds-default {
	0%,
	20%,
	80%,
	100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.5);
	}
}


/*-- Default loader --*/


/*-- Ellipsis loader --*/

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
	div {
		position: absolute;
		top: 27px;
		width: 11px;
		height: 11px;
		border-radius: 50%;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
		&:nth-child(1) {
			left: 6px;
			animation: lds-ellipsis1 0.6s infinite;
		}
		&:nth-child(2) {
			left: 6px;
			animation: lds-ellipsis2 0.6s infinite;
		}
		&:nth-child(3) {
			left: 26px;
			animation: lds-ellipsis2 0.6s infinite;
		}
		&:nth-child(4) {
			left: 45px;
			animation: lds-ellipsis3 0.6s infinite;
		}
	}
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(19px, 0);
	}
}


/*-- Ellipsis loader --*/


/*-- Grid loader --*/

.lds-grid {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
	div {
		position: absolute;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		animation: lds-grid 1.2s linear infinite;
		&:nth-child(1) {
			top: 6px;
			left: 6px;
			animation-delay: 0s;
		}
		&:nth-child(2) {
			top: 6px;
			left: 26px;
			animation-delay: -0.4s;
		}
		&:nth-child(3) {
			top: 6px;
			left: 45px;
			animation-delay: -0.8s;
		}
		&:nth-child(4) {
			top: 26px;
			left: 6px;
			animation-delay: -0.4s;
		}
		&:nth-child(5) {
			top: 26px;
			left: 26px;
			animation-delay: -0.8s;
		}
		&:nth-child(6) {
			top: 26px;
			left: 45px;
			animation-delay: -1.2s;
		}
		&:nth-child(7) {
			top: 45px;
			left: 6px;
			animation-delay: -0.8s;
		}
		&:nth-child(8) {
			top: 45px;
			left: 26px;
			animation-delay: -1.2s;
		}
		&:nth-child(9) {
			top: 45px;
			left: 45px;
			animation-delay: -1.6s;
		}
	}
}

@keyframes lds-grid {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
}


/*-- Grid loader --*/


/*-- Disk loader --*/

@keyframes lds-disk {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes lds-disk {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.lds-disk {
	position: relative;
	>div div {
		&:nth-child(1) {
			position: absolute;
			top: 30px;
			left: 30px;
			width: 140px;
			height: 140px;
			border-radius: 50%;
		}
		&:nth-child(2) {
			position: absolute;
			top: 39.800000000000004px;
			left: 79px;
			width: 42px;
			height: 42px;
			border-radius: 50%;
			background: #ffd391;
			-webkit-animation: lds-disk 1s linear infinite;
			animation: lds-disk 1s linear infinite;
			-webkit-transform-origin: 21px 60.199999999999996px;
			transform-origin: 21px 60.199999999999996px;
		}
	}
	width: 64px !important;
	height: 64px !important;
	-webkit-transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	text-align: center;
	margin: 0 auto;
}


/*-- Disk loader --*/


/*-- Ball2 loader --*/

@keyframes lds-ball2 {
	0%,
	100% {
		animation-timing-function: cubic-bezier(0.2, 0, 0.8, 1);
	}
	50% {
		animation-timing-function: cubic-bezier(0, 0.2, 1, 0.8);
	}
	0% {
		-webkit-transform: rotate(0deg) translate(0, -20px) scale(0);
		transform: rotate(0deg) translate(0, -20px) scale(0);
	}
	50% {
		-webkit-transform: rotate(180deg) translate(0, -20px) scale(1);
		transform: rotate(180deg) translate(0, -20px) scale(1);
	}
	100% {
		-webkit-transform: rotate(360deg) translate(0, -20px) scale(0);
		transform: rotate(360deg) translate(0, -20px) scale(0);
	}
}

@-webkit-keyframes lds-ball2 {
	0%,
	100% {
		animation-timing-function: cubic-bezier(0.2, 0, 0.8, 1);
	}
	50% {
		animation-timing-function: cubic-bezier(0, 0.2, 1, 0.8);
	}
	0% {
		-webkit-transform: rotate(0deg) translate(0, -20px) scale(0);
		transform: rotate(0deg) translate(0, -20px) scale(0);
	}
	50% {
		-webkit-transform: rotate(180deg) translate(0, -20px) scale(1);
		transform: rotate(180deg) translate(0, -20px) scale(1);
	}
	100% {
		-webkit-transform: rotate(360deg) translate(0, -20px) scale(0);
		transform: rotate(360deg) translate(0, -20px) scale(0);
	}
}

div.lds-ball2 {
	position: relative;
	div {
		position: absolute;
		top: 70px;
		left: 70px;
		-webkit-animation: lds-ball2 1s linear infinite;
		animation: lds-ball2 1s linear infinite;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		&:last-child {
			-webkit-animation-delay: -0.5s;
			animation-delay: -0.5s;
			background: $success;
		}
	}
}

.lds-ball2 {
	width: 64px !important;
	height: 64px !important;
	-webkit-transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	margin: 0 auto;
	text-align: center;
}


/*-- Ball2 loader --*/


/*-- Ball loader --*/

@keyframes lds-ball {
	0%,
	100% {
		animation-timing-function: cubic-bezier(0.45, 0, 0.9, 0.55);
	}
	0% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
	50% {
		-webkit-transform: translate(0, 108px);
		transform: translate(0, 108px);
		animation-timing-function: cubic-bezier(0, 0.45, 0.55, 0.9);
	}
	100% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@-webkit-keyframes lds-ball {
	0%,
	100% {
		animation-timing-function: cubic-bezier(0.45, 0, 0.9, 0.55);
	}
	0% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
	50% {
		-webkit-transform: translate(0, 108px);
		transform: translate(0, 108px);
		animation-timing-function: cubic-bezier(0, 0.45, 0.55, 0.9);
	}
	100% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

.lds-ball {
	position: relative;
	div {
		position: absolute;
		width: 52px;
		height: 52px;
		border-radius: 50%;
		left: 74px;
		top: 20px;
		-webkit-animation: lds-ball 1s linear infinite;
		animation: lds-ball 1s linear infinite;
	}
	width: 64px !important;
	height: 64px !important;
	-webkit-transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	margin: 0 auto;
	text-align: center;
}


/*-- Ball loader --*/


/*-- Bars loader --*/

@keyframes lds-bars {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes lds-bars {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

.lds-bars {
	position: relative;
	div {
		position: absolute;
		width: 20px;
		height: 80px;
		top: 60px;
		-webkit-animation: lds-bars 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		animation: lds-bars 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		&:nth-child(1) {
			left: 30px;
			-webkit-animation-delay: -0.6s;
			animation-delay: -0.6s;
		}
		&:nth-child(2) {
			left: 70px;
			-webkit-animation-delay: -0.4s;
			animation-delay: -0.4s;
		}
		&:nth-child(3) {
			left: 110px;
			-webkit-animation-delay: -0.2s;
			animation-delay: -0.2s;
		}
		&:nth-child(4) {
			left: 150px;
		}
	}
	width: 64px !important;
	height: 64px !important;
	-webkit-transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	margin: 0 auto;
	text-align: center;
}

.lds-blocks {
	position: relative;
	div {
		position: absolute;
		width: 40px;
		height: 40px;
		-webkit-animation: lds-blocks 1s linear infinite;
		animation: lds-blocks 1s linear infinite;
	}
	width: 64px !important;
	height: 64px !important;
	-webkit-transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	margin: 0 auto;
}


/*-- Bars loader --*/


/*-- Cube loader --*/

@keyframes lds-cube {
	0% {
		-webkit-transform: scale(1.5);
		transform: scale(1.5);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes lds-cube {
	0% {
		-webkit-transform: scale(1.5);
		transform: scale(1.5);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.lds-cube {
	position: relative;
	div {
		position: absolute;
		width: 80px;
		height: 80px;
		top: 10px;
		left: 10px;
		-webkit-animation: lds-cube 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
		animation: lds-cube 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
		-webkit-animation-delay: -0.3s;
		animation-delay: -0.3s;
		&:nth-child(2) {
			top: 10px;
			left: 110px;
			background: $success;
			-webkit-animation-delay: -0.2s;
			animation-delay: -0.2s;
		}
		&:nth-child(3) {
			top: 110px;
			left: 110px;
			background: #ff5630;
			-webkit-animation-delay: 0s;
			animation-delay: 0s;
		}
		&:nth-child(4) {
			top: 110px;
			left: 10px;
			background: $info;
			-webkit-animation-delay: -0.1s;
			animation-delay: -0.1s;
		}
	}
	width: 64px !important;
	height: 64px !important;
	-webkit-transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	margin: 0 auto;
}


/*-- Cube loader --*/


/*-- Eclipse loader --*/

@keyframes lds-eclipse {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	50% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes lds-eclipse {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	50% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.lds-eclipse {
	position: relative;
	div {
		position: absolute;
		-webkit-animation: lds-eclipse 1s linear infinite;
		animation: lds-eclipse 1s linear infinite;
		width: 160px;
		height: 160px;
		top: 20px;
		left: 20px;
		border-radius: 50%;
		-webkit-transform-origin: 80px 82px;
		transform-origin: 80px 82px;
	}
	width: 64px !important;
	height: 64px !important;
	-webkit-transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	margin: 0 auto;
}


/*-- Eclipse loader --*/


/*-- Flickr loader --*/

@keyframes lds-flickr-opacity {
	0% {
		-webkit-transform: translate(0 0);
		transform: translate(0 0);
		opacity: 1;
	}
	49.99% {
		opacity: 1;
		-webkit-transform: translate(80px, 0);
		transform: translate(80px, 0);
	}
	50% {
		opacity: 0;
		-webkit-transform: translate(80px, 0);
		transform: translate(80px, 0);
	}
	100% {
		opacity: 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@-webkit-keyframes lds-flickr-opacity {
	0% {
		-webkit-transform: translate(0 0);
		transform: translate(0 0);
		opacity: 1;
	}
	49.99% {
		opacity: 1;
		-webkit-transform: translate(80px, 0);
		transform: translate(80px, 0);
	}
	50% {
		opacity: 0;
		-webkit-transform: translate(80px, 0);
		transform: translate(80px, 0);
	}
	100% {
		opacity: 0;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@keyframes lds-flickr {
	0% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
	50% {
		-webkit-transform: translate(80px, 0);
		transform: translate(80px, 0);
	}
	100% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@-webkit-keyframes lds-flickr {
	0% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
	50% {
		-webkit-transform: translate(80px, 0);
		transform: translate(80px, 0);
	}
	100% {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

.lds-flickr {
	position: relative;
	div {
		position: absolute;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		top: 60px;
		left: 20px;
		&:nth-child(1) {
			-webkit-animation: lds-flickr 1s linear infinite;
			animation: lds-flickr 1s linear infinite;
			-webkit-animation-delay: -0.5s;
			animation-delay: -0.5s;
		}
		&:nth-child(2) {
			background: $success;
			-webkit-animation: lds-flickr 1s linear infinite;
			animation: lds-flickr 1s linear infinite;
			-webkit-animation-delay: 0s;
			animation-delay: 0s;
		}
		&:nth-child(3) {
			-webkit-animation: lds-flickr-opacity 1s linear infinite;
			animation: lds-flickr-opacity 1s linear infinite;
			-webkit-animation-delay: -0.5s;
			animation-delay: -0.5s;
		}
	}
	width: 64px !important;
	height: 64px !important;
	-webkit-transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	margin: 0 auto;
}


/*-- Flickr loader --*/


/*-- Gear loader --*/

@keyframes lds-gear {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	50% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes lds-gear {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	50% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.lds-gear {
	>div {
		-webkit-transform-origin: 100px 100px;
		transform-origin: 100px 100px;
		-webkit-animation: lds-gear 1s infinite linear;
		animation: lds-gear 1s infinite linear;
		position: relative;
		div {
			position: absolute;
			width: 26px;
			height: 192px;
			left: 100px;
			top: 100px;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			&:nth-child(1) {
				width: 152px;
				height: 152px;
				border-radius: 50%;
			}
			&:nth-child(3) {
				-webkit-transform: translate(-50%, -50%) rotate(30deg);
				transform: translate(-50%, -50%) rotate(30deg);
			}
			&:nth-child(4) {
				-webkit-transform: translate(-50%, -50%) rotate(60deg);
				transform: translate(-50%, -50%) rotate(60deg);
			}
			&:nth-child(5) {
				-webkit-transform: translate(-50%, -50%) rotate(90deg);
				transform: translate(-50%, -50%) rotate(90deg);
			}
			&:nth-child(6) {
				-webkit-transform: translate(-50%, -50%) rotate(120deg);
				transform: translate(-50%, -50%) rotate(120deg);
			}
			&:nth-child(7) {
				-webkit-transform: translate(-50%, -50%) rotate(150deg);
				transform: translate(-50%, -50%) rotate(150deg);
			}
			&:nth-child(8) {
				width: 80px;
				height: 80px;
				background: $white;
				border-radius: 50%;
			}
		}
	}
	width: 64px !important;
	height: 64px !important;
	-webkit-transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	margin: 0 auto;
}


/*-- Gear loader --*/


/*-- Magnify loader --*/

@keyframes lds-magnify {
	0% {
		-webkit-transform: translate(2px, 2px);
		transform: translate(2px, 2px);
	}
	33.33% {
		-webkit-transform: translate(102px, 2px);
		transform: translate(102px, 2px);
	}
	66.66% {
		-webkit-transform: translate(42px, 102px);
		transform: translate(42px, 102px);
	}
	100% {
		-webkit-transform: translate(2px, 2px);
		transform: translate(2px, 2px);
	}
}

@-webkit-keyframes lds-magnify {
	0% {
		-webkit-transform: translate(2px, 2px);
		transform: translate(2px, 2px);
	}
	33.33% {
		-webkit-transform: translate(102px, 2px);
		transform: translate(102px, 2px);
	}
	66.66% {
		-webkit-transform: translate(42px, 102px);
		transform: translate(42px, 102px);
	}
	100% {
		-webkit-transform: translate(2px, 2px);
		transform: translate(2px, 2px);
	}
}

.lds-magnify {
	position: relative;
	>div {
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 100px 100px;
		transform-origin: 100px 100px;
		>div {
			-webkit-animation: lds-magnify 1s linear infinite;
			animation: lds-magnify 1s linear infinite;
			position: absolute;
			div {
				&:nth-child(1) {
					width: 96px;
					height: 96px;
					border-radius: 50%;
					background: $white;
				}
				&:nth-child(2) {
					width: 17px;
					height: 51px;
					-webkit-transform: rotate(-45deg);
					transform: rotate(-45deg);
					border-radius: 0 0 8px 8px;
					position: absolute;
					top: 68px;
					left: 85px;
				}
			}
		}
	}
	width: 64px !important;
	height: 64px !important;
	-webkit-transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	margin: 0 auto;
}


/*-- Magnify loader --*/


/*-- Pacman loader --*/

@keyframes lds-pacman-1 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	50% {
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}

@-webkit-keyframes lds-pacman-1 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	50% {
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}

@keyframes lds-pacman-2 {
	0% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	50% {
		-webkit-transform: rotate(225deg);
		transform: rotate(225deg);
	}
	100% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
}

@-webkit-keyframes lds-pacman-2 {
	0% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	50% {
		-webkit-transform: rotate(225deg);
		transform: rotate(225deg);
	}
	100% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
}

@keyframes lds-pacman-3 {
	0% {
		-webkit-transform: translate(190px, 0);
		transform: translate(190px, 0);
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(70px, 0);
		transform: translate(70px, 0);
		opacity: 1;
	}
}

@-webkit-keyframes lds-pacman-3 {
	0% {
		-webkit-transform: translate(190px, 0);
		transform: translate(190px, 0);
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
	100% {
		-webkit-transform: translate(70px, 0);
		transform: translate(70px, 0);
		opacity: 1;
	}
}

.lds-pacman {
	position: relative;
	>div {
		&:nth-child(2) div {
			position: absolute;
			top: 40px;
			left: 40px;
			width: 120px;
			height: 60px;
			border-radius: 120px 120px 0 0;
			-webkit-animation: lds-pacman-1 1s linear infinite;
			animation: lds-pacman-1 1s linear infinite;
			-webkit-transform-origin: 60px 60px;
			transform-origin: 60px 60px;
			&:nth-child(2) {
				-webkit-animation: lds-pacman-2 1s linear infinite;
				animation: lds-pacman-2 1s linear infinite;
			}
		}
		&:nth-child(1) div {
			position: absolute;
			top: 92px;
			left: -8px;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			background: $success;
			-webkit-animation: lds-pacman-3 1s linear infinite;
			animation: lds-pacman-3 1s linear infinite;
			&:nth-child(1) {
				-webkit-animation-delay: -0.67s;
				animation-delay: -0.67s;
			}
			&:nth-child(2) {
				-webkit-animation-delay: -0.33s;
				animation-delay: -0.33s;
			}
			&:nth-child(3) {
				-webkit-animation-delay: 0s;
				animation-delay: 0s;
			}
		}
	}
	width: 64px !important;
	height: 64px !important;
	-webkit-transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	transform: translate(-32px, -32px) scale(0.32) translate(32px, 32px);
	margin: 0 auto;
}


/*-- Pacman loader --*/