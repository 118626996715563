/*-----Jumbotron-----*/

.jumbotron {
	padding: 2rem 1rem;
	margin-bottom: 2rem;
	background-color: rgb(236, 238, 244);
	border-radius: 3px;
}

.jumbotron-fluid {
	padding-right: 0;
	padding-left: 0;
	border-radius: 0;
}


/*-----Jumbotron-----*/