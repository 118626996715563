/*----- Timeline ------*/

.timeline {
	position: relative;
	margin: 0 0 2rem;
	padding: 0;
	list-style: none;
	margin-top: 22px;
	&:before {
		background-color: #eff2f7;
		position: absolute;
		display: block;
		content: '';
		height: 100%;
		top: 0;
		bottom: 0;
		left: 4px;
	}
}

.timeline-item {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	padding-left: 2rem;
	margin: .5rem 0;
	&:first-child:before,
	&:last-child:before {
		content: '';
		position: absolute;
		background: $white;
		width: 1px;
		left: .25rem;
	}
	&:first-child {
		margin-top: 0;
		&:before {
			top: 0;
			height: .5rem;
		}
	}
	&:last-child {
		margin-bottom: 0;
		&:before {
			top: .5rem;
			bottom: 0;
		}
	}
}

.timeline-badge {
	position: absolute;
	display: block;
	width: 0.4375rem;
	height: 0.4375rem;
	left: 1px;
	top: .5rem;
	border-radius: 100%;
	border: 1px solid $white;
	background: #adb5bd;
}

.timeline-time {
	white-space: nowrap;
	margin-left: auto;
	color: #8c98b9;
	font-size: 87.5%;
}

.timeline__item:after {
	background: $white !important;
}

.timeline__content {
	background-color: $white;
	border-radius: 10px;
	display: block;
	padding: 1.25rem;
	position: relative;
}

.cbp_tmtimeline {
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		width: 2px;
		background: $background;
		left: 20%;
		margin-left: -6px;
	}
	>li {
		position: relative;
		&:first-child .cbp_tmtime span.large {
			font-size: 17px !important;
			font-weight: 600;
		}
		&:nth-child(odd) {
			.cbp_tmtime span:last-child {
				font-size: 13px;
			}
			.cbp_tmlabel {
				background: #f1f5ff;
				&:after {
					border-right-color: $border;
				}
			}
		}
		.empty span {
			color: $black-5;
		}
		.cbp_tmtime {
			display: block;
			width: 21%;
			padding-right: 70px;
			position: absolute;
			span {
				display: block;
				text-align: right;
				&:first-child {
					font-size: 15px;
					font-weight: 600;
				}
				&:last-child {
					font-size: 14px;
				}
			}
		}
		.cbp_tmlabel {
			margin: 0 0 30px 25%;
			background: #f1f5ff;
			padding: 1.2em;
			position: relative;
			border: 1px solid $border;
			border-radius:4px;
			&:after {
				right: 100%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-right-color: $border;
				border-width: 12px;
				top: 10px;
				left: -24px;
			}
			&:before {
				right: 100%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-right-color: #f1f5ff;
				border-width: 10px;
				top: 12px;
				z-index: 1;
				left: -20px;
			}
			blockquote {
				font-size: 16px;
			}
			.map-checkin {
				border: 5px solid rgba(235, 235, 235, 0.2);
				-moz-box-shadow: 0px 0px 0px 1px #ebebeb;
				-webkit-box-shadow: 0px 0px 0px 1px #ebebeb;
				box-shadow: 0px 0px 0px 1px #ebebeb;
				background: #3d3780 !important;
			}
			h2 {
				margin: 0px;
				padding: 0 0 5px 0;
				line-height: 26px;
				font-size: 18px;
				font-weight: 600;
				a {
					font-size: 17px;
					&:hover {
						text-decoration: none;
					}
				}
				span {
					font-size: 14px;
					font-weight: 400;
				}
			}
			p {
				margin-bottom: 0;
			}
		}
		.cbp_tmicon {
			width: 40px;
			height: 40px;
			speak-as: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			font-size: 1.4em;
			line-height: 40px;
			-webkit-font-smoothing: antialiased;
			position: absolute;
			color: $white;
			background: #46a4da;
			border-radius: 50%;
			text-align: center;
			left: 20%;
			top: 0;
			margin: 0 0 0 -25px;
		}
	}
	.map {
		padding-top: 0 !important;
	}
}


/*vertical-timline*/


/*vertical-timline 02*/


/** timeline left **/

.timelineleft {
	position: relative;
	margin: 0 0 30px 0;
	padding: 0;
	list-style: none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		width: 2px;
		background: #e0e4ef;
		left: 31px;
		margin: 0;
		border-radius: 4px;
	}
	>li {
		position: relative;
		margin-right: 10px;
		margin-bottom: 15px;
	}
}

.timelinelft>li:before {
	content: " ";
	display: table;
}

.timelineleft> {
	li:after {
		content: " ";
		display: table;
	}
	.timeleft-label>span {
		font-weight: 600;
		padding: 5px;
		display: inline-block;
		background-color: #1753fc;
		color: $white;
		border-radius: 4px;
	}
	li {
		&:after {
			clear: both;
		}
		>.timelineleft-item {
			margin-top: 0;
			background: #f1f5ff;
			color: #444;
			margin-left: 60px;
			margin-right: 15px;
			padding: 0;
			position: relative;
			border-radius: 7px;
			border: 1px solid $border;
			> {
				.time {
					color: #999;
					float: right;
					padding: 12px;
					font-size: 12px;
				}
				.timelineleft-header {
					margin: 0;
					color: #555;
					padding: .75rem 1.25rem;
					font-size: 14px;
					line-height: 1.1;
					font-weight: 400;
					span {
						margin-left: 3px;
					}
					>a {
						font-weight: 500;
						font-size: 18px;
					}
				}
			}
		}
	}
}

.timelineleft-body img {
	width: 150px;
	height: 100px;
}

.timelineleft>li>.timelineleft-item> {
	.timelineleft-body,
	.timelineleft-footer {
		padding: .75rem 1.25rem;
	}
}

.timelineleft-body {
	font-size: 14px;
}

.timelineleft>li> {
	.fa,
	.glyphicon,
	.ion {
		width: 30px;
		height: 30px;
		font-size: 15px;
		line-height: 30px;
		position: absolute;
		color: $white;
		background: #1753fc;
		border-radius: 50%;
		text-align: center;
		left: 18px;
		top: 0;
	}
}


/*vertical-timline 02*/


/*vertical-timline 03*/

.timeline {
	>li {
		&:nth-child(even) {
			position: relative;
			margin-bottom: 50px;
			height: 180px;
			right: -100px;
		}
		&:nth-child(odd) {
			position: relative;
			margin-bottom: 50px;
			height: 180px;
			left: -100px;
		}
		&:before {
			content: " ";
			display: table;
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
			min-height: 170px;
		}
		.timeline-panel {
			position: relative;
			float: left;
			width: 41%;
			padding: 20px 20px 20px 30px;
			text-align: right;
			background: $white;
			box-shadow: 0 0 3px rgba(31, 30, 47, 0.05);
			&:before {
				right: auto;
				left: -15px;
				border-right-width: 15px;
				border-left-width: 0;
			}
			&:after {
				right: auto;
				left: -14px;
				border-right-width: 14px;
				border-left-width: 0;
			}
		}
		.timeline-image {
			z-index: 10;
			position: absolute;
			left: 50%;
			width: 150px;
			height: 150px;
			margin-left: -80px;
			overflow: hidden;
			h4 {
				margin-top: 12px;
				font-size: 10px;
				line-height: 14px;
			}
		}
		&.timeline-inverted>.timeline-panel {
			float: right;
			padding: 20px 30px 20px 20px;
			text-align: left;
			&:before {
				right: auto;
				left: -15px;
				border-right-width: 15px;
				border-left-width: 0;
			}
			&:after {
				right: auto;
				left: -14px;
				border-right-width: 14px;
				border-left-width: 0;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	.timeline-heading h4 {
		margin-top: 8px;
		margin-bottom: 8px;
		padding: 0;
		font-weight: 600;
		&.subheading {
			margin: 0;
			padding: 0;
			text-transform: none;
			font-size: 16px;
			font-weight: 400;
			color: #616267;
		}
	}
	.timeline-body> {
		p,
		ul {
			margin-bottom: 0;
			color: #808080;
		}
	}
	>li {
		&:nth-child(odd) .line:before {
			content: "";
			position: absolute;
			top: 60px;
			bottom: 0;
			left: 690px;
			width: 4px;
			height: 340px;
			-ms-transform: rotate(-44deg);
			/* IE 9 */
			-webkit-transform: rotate(-44deg);
			/* Safari */
			transform: rotate(-44deg);
		}
		&:nth-child(even) .line:before {
			content: "";
			position: absolute;
			top: 60px;
			bottom: 0;
			left: 450px;
			width: 4px;
			height: 340px;
			-ms-transform: rotate(44deg);
			/* IE 9 */
			-webkit-transform: rotate(44deg);
			/* Safari */
			transform: rotate(44deg);
		}
	}
}


/*Style for even div.line*/


/*Style for odd div.line*/

.latest-timeline-1 ul.timeline-1 {
	list-style-type: none;
	position: relative;
	&:before {
		content: ' ';
		border: 2px dotted $background;
		margin: 20px 20px 0 22px;
		display: inline-block;
		position: absolute;
		left: -5px;
		width: 2px;
		height: 100%;
		z-index: 0;
	}
	>li {
		margin: 22px 0;
		padding-left: 3rem;
		.product-icon {
			width: 35px;
			height: 35px;
			text-align: center;
			border-radius: 50%;
			position: absolute;
			left: 1px;
			background-color: #f3f6f7;
			font-size: 20px;
			line-height: 35px;
			font-size: 15px;
		}
		&:nth-child(2):before {
			border: 3px solid #fb1c52;
		}
		&:nth-child(3):before {
			border: 3px solid #e9b307;
		}
		&:nth-child(4):before {
			border: 3px solid #4481eb;
		}
		&:last-child:before {
			border: 3px solid #27af06;
		}
	}
}

.product-timeline ul.timeline-1 {
	list-style-type: none;
	position: relative;
	&:before {
		content: ' ';
		border: 1px solid #e5eafb;
		margin: 20px 20px 0 22px;
		display: inline-block;
		position: absolute;
		left: -2px;
		width: 2px;
		height: 94%;
		z-index: 0;
	}
	>li {
		margin: 22px 0;
		padding-left: 3.5rem;
		.product-icon {
			width: 40px;
			height: 40px;
			text-align: center;
			border-radius: 50%;
			position: absolute;
			left: 1px;
			background-color: #f3f6f7;
			font-size: 20px;
			line-height: 40px;
			font-size: 21px;
		}
	}
}

/*latetes news timeline */
  
  .latest-timeline {
	ul.activity-timeline {
	  list-style-type: none;
	  position: relative;
  
	  &:before {
		content: ' ';
		display: inline-block;
		position: absolute;
		left: 10px;
		height: 100%;
		z-index: 1;
		border-left: 2px solid $border;
	  }
  
	  > li {
		margin: 20px 0;
		padding-left: 2.5rem;
  
		&:before {
		  content: ' ';
		  background: white;
		  display: inline-block;
		  position: absolute;
		  border-radius: 50%;
		  border: 3px solid #1753fc;
		  left: 5px;
		  width: 13px;
		  height: 13px;
		  z-index: 1;
		}
  
		&:first-child:before {
		  border: 3px solid #02c3ee;
		}
  
		&:nth-child(2):before {
		  border: 3px solid #f92136;
		}
  
		&:nth-child(3):before {
		  border: 3px solid #f29f03;
		}
  
		&:nth-child(4):before {
		  border: 3px solid #f43f86;
		}
  
		&:nth-child(5):before {
		  border: 3px solid #05a5ba;
		}
  
		&:nth-child(6):before {
		  border: 3px solid #461f6e;
		}
  
		&:last-child:before {
		  border: 3px solid #f75b00;
		}
		.time-data{
			font-weight:500;
		}
	  }
	}
  }
  
