/*----- Avatars ------*/

.self .avatar {
	order: 2;
	&:after {
		content: "";
		position: relative;
		display: inline-block;
		bottom: -14px;
		right: 26px;
		width: 0px;
		height: 0px;
		border: 5px solid #eff2f7;
		border-right-color: transparent;
		border-top-color: transparent;
		box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
	}
}
.country-selector .avatar {
	width: 1.4rem;
	height: 1rem;
	line-height: 1rem;
}

.avatar {
	width: 2rem !important;
	height: 2rem !important;
	line-height: 2rem !important;
	display: inline-block;
	position: relative;
	text-align: center;
	color: $white;
	font-weight: 500;
	vertical-align: bottom;
	font-size: .875rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.avatar-messages {
	width: 1rem !important;
	height: 1rem !important;
	line-height: 1rem !important;
	display: inline-block;
	position: relative;
	text-align: center;
	color: $white;
	font-weight: 600;
	vertical-align: bottom;
	font-size: .70rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-radius: 50%;
	left: 8px;
}

.avatar i {
	font-size: 125%;
	vertical-align: sub;
}

.avatar-status {
	position: absolute;
	right: -2px;
	bottom: -2px;
	width: .75rem;
	height: .75rem;
	border: 2px solid $white;
	background: #aaadb2;
	border-radius: 50%;
}

.avatar-sm {
	width: 1.5rem !important;
	height: 1.5rem !important;
	line-height: 1.5rem !important;
	font-size: .75rem;
}

.avatar-md {
	width: 2.5rem !important;
	height: 2.5rem !important;
	line-height: 2.5rem !important;
	font-size: 1rem;
}

.avatar-lg {
	width: 3rem !important;
	height: 3rem !important;
	line-height: 3rem !important;
	font-size: 1.25rem;
}

.avatar-xl {
	width: 4rem !important;
	height: 4rem !important;
	line-height: 4rem !important;
	font-size: 1.75rem;
}

.avatar-xxl {
	width: 5rem !important;
	height: 5rem !important;
	line-height: 5rem !important;
	font-size: 2rem;
}

.avatar-placeholder {
	background: #ced4da url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="134" height="134" viewBox="0 0 134 134"><path fill="#aaadb2" d="M65.92 66.34h2.16c14.802.42 30.928 6.062 29.283 20.35l-1.618 13.32c-.844 6.815-5.208 7.828-13.972 7.866H52.23c-8.764-.038-13.13-1.05-13.973-7.865l-1.62-13.32C34.994 72.4 51.12 66.76 65.92 66.34zM49.432 43.934c0-9.82 7.99-17.81 17.807-17.81 9.82 0 17.81 7.99 17.81 17.81 0 9.82-7.99 17.807-17.81 17.807-9.82 0-17.808-7.987-17.808-17.806z"/></svg>') no-repeat (center / 80%);
}

.avatar-list {
	margin: 0 0 -.5rem;
	padding: 0;
	font-size: 0;
	.avatar {
		margin-bottom: .5rem;
		&:not(:last-child) {
			margin-right: .5rem;
		}
	}
}

.avatar-list-stacked .avatar {
	margin-right: -.8em !important;
	box-shadow: 0 0 0 2px $white;
}

.avatar-blue {
	background-color: #c8d9f1;
	color: $black;
}

.avatar-indigo {
	background-color: #d1d5f0;
	color: $indigo;
}

.avatar-purple {
	background-color: #e4cff9;
	color: $white;
}

.avatar-pink {
	background-color: #fcd3e1;
	color: $pink;
}

.avatar-red {
	background-color: #f0bcbc;
	color: #f94859;
}

.avatar-orange {
	background-color: #fee0c7;
	color: $orange;
}

.avatar-yellow {
	background-color: #fbedb7;
	color: $warning;
}

.avatar-green {
	background-color: #cfeab3;
	color: $success;
}

.avatar-teal {
	background-color: #bfefea;
	color: $teal;
}

.avatar-cyan {
	background-color: #b9e3ea;
	color: $cyan;
}

.avatar-white {
	background-color: white;
	color: $white;
}

.avatar-gray {
	background-color: #dbdde0;
	color: #aaadb2;
}

.avatar-gray-dark {
	background-color: #c2c4c6;
	color: #3d3f4e;
}

.avatar-azure {
	background-color: #c7e6fb;
	color: #0a7ffb;
}

.avatar-lime {
	background-color: #d7f2c2;
	color: #bfff00;
}

.product-price {
	font-size: 1rem;
	strong {
		font-size: 1.5rem;
	}
}

.chip .avatar {
	float: left;
	margin: 0 .5rem 0 -.75rem;
	height: 2rem;
	width: 2rem;
	border-radius: 50%;
}
.avatar.avatar-xl.brround .avatar-status{ 
	right:8px;
}
