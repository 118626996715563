/*-----Pricing-----*/

.pricing {
	color: #fff;
	border-radius: 7px;
	.card-category {
		background: rgba(255, 255, 255, 0.3);
		padding: 10px 0;
		color: #Fff;
	}
	.list-unstyled li {
		border-bottom: 1px solid #eff2f7;
		padding: 7px 0;
	}
}

.pricing1 {
	color: #1f252d;
	border-radius: 7px;
	.list-unstyled li {
		padding: 7px 0;
	}
	.card-category {
		background: rgba(255, 255, 255, 0.3);
		padding: 40px 0;
		color: #Fff;
	}
}


.table> {
	tbody>tr>th {
		font-weight: 500;
		-webkit-transition: all .3s ease;
	}
	tfoot>tr> {
		td,
		th {
			font-weight: 500;
			-webkit-transition: all .3s ease;
		}
	}
	thead>tr> {
		td,
		th {
			font-weight: 500;
			-webkit-transition: all .3s ease;
		}
	}
}

.col-sm-4 .pricing-table .table td {
	padding: 0.75rem;
	vertical-align: top;
	text-align: left;
}

.price {
	.list-group-item {
		border-bottom: 1px solid rgba(250, 250, 250, 0.5);
	}
	.panel-footer {
		border-bottom: 0px;
		background-color: #fff;
	}
	&.panel-color>.panel-body {
		background-color: #fff;
	}
}


/*--pricing style04--*/

.price-img img {
	width: 50%;
	height: 50%;
	margin-bottom: 1rem;
}

.price-1 .list-unstyled li {
	padding: 7px 0;
}

.danger .pricing-divider {
	background: linear-gradient(to bottom right, #f94859 0%, #f94971 100%) !important;
	padding: 1em 0 4em;
	position: relative;
}

.success .pricing-divider {
	background: linear-gradient(to bottom right, #62fb62 0%, #21a544 100%) !important;
	padding: 1em 0 4em;
	position: relative;
}

.pricing-divider-img {
	position: absolute;
	left: 0;
	width: 100%;
}

.pricing .list-unstyled li {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	padding: 7px 0;
}


/*----Pricing Tables style 04----*/


/*----Pricing Tables style 03----*/

.pri-header {
	padding: .75rem 1.25rem;
	margin-bottom: 0;
	background-color: #f1f1ff;
	border-bottom: 1px solid #efefff;
}

.pricing-table {
	width: 100%;
	margin: 0 auto;
	transition: all .3s ease;
	-o-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	border-radius: 0px;
	&:hover {
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
		> {
			.panel-footer-landing {
				box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) inset;
				-webkit-transition: all .3s ease;
			}
			.panel> {
				.panel-body-landing {
					background: #510fa8;
					-webkit-transition: all .3s ease;
				}
				.panel-heading-landing-box {
					background: #fff !important;
					color: #333 !important;
					-webkit-transition: all .3s ease;
				}
				.controle-header {
					background: #510fa8 !important;
					/*    border: solid 2px #5CB85C !important;*/
					-webkit-transition: all .3s ease;
				}
				.panel-footer {
					background: #510fa8 !important;
					/*    border: solid 2px #5CB85C !important;*/
					-webkit-transition: all .3s ease;
					text-align: center;
				}
				.controle-header>.panel-title-landing {
					color: #fff !important;
					-webkit-transition: all .3s ease;
					font-size: 35px;
				}
			}
		}
		>.panel>.panel-body-landing>.table>tbody>tr>td {
			font-weight: 500;
			-webkit-transition: all .3s ease;
		}
	}
	>.panel>.controle-header>.panel-title-landing {
		color: #000 !important;
		font-size: 35px;
	}
	text-align: center;
	-webkit-transition: .3s ease-in;
	-o-transition: .3s ease-in;
	transition: .3s ease-in;
	position: relative;
	z-index: 0;
	overflow: hidden;
	background: #fff;
	border: 1px solid $border;
	margin-bottom: 30px;
	box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
	.price-header {
		position: relative;
		padding: 30px 0 25px 0;
		border-bottom:1px solid $border;
		background-color: $white;
		-webkit-transition: .3s ease-in;
		-o-transition: .3s ease-in;
		transition: .3s ease-in;
		.permonth {
			position: absolute;
			left: 50%;
			bottom: -20px;
			background-color: #fff;
			padding: 10px 35px;
			border-radius: 25px;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
			-webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
			box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 400;
			color: #383838;
		}
	}
	&:hover .price-header .title {
		color: #fff;
	}
	.price-header .title {
		color: #282828;
		font-size: 21px;
		line-height: 26px;
		text-transform: uppercase;
		font-weight: 600;
		margin-top: 20px;
		-webkit-transition: .3s ease-in;
		-o-transition: .3s ease-in;
		transition: .3s ease-in;
		margin-bottom: 15px;
	}
	&:hover .price {
		color: #fff;
	}
	.price {
		font-size: 50px;
		color: #282828;
		font-weight: 500;
		-webkit-transition: .3s ease-in;
		-o-transition: .3s ease-in;
		transition: .3s ease-in;
		text-align: center;
		position: relative;
		transition: .3s ease-in;
		margin-bottom: 15px;
		.dollar {
			font-size: 33px;
			line-height: 33px;
			position: relative;
			top: -12px;
		}
	}
	.price-body ul {
		margin: 0;
		padding: 0;
		margin-top: 40px;
		li {
			&:last-child {
				border-bottom: none;
				margin-bottom: 0;
				padding-bottom: 0;
			}
			list-style: none;
			display: block;
			color: #282828;
			padding: 15px 0;
			-webkit-transition: .3s ease-in;
			-o-transition: .3s ease-in;
			transition: .3s ease-in;
			border-bottom: 1px solid #efefff;
			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.price-footer {
		margin-top: 40px;
		.order-btn {
			margin-bottom: 30px;
		}
	}
}


/*----Pricing Tables 03----*/


/*----Pricing Tables 02----*/

.pt-inner {
	text-align: center;
	.pti-header {
		padding: 45px 10px 70px;
		color: #fff;
		position: relative;
		border: 1px solid rgba(107, 122, 144, 0.1);
		border-bottom: 0;
		>h2 {
			margin: 0;
			line-height: 100%;
			font-weight: 100;
			font-size: 50px;
			color: #fff;
			small {
				letter-spacing: 0;
				vertical-align: top;
				font-size: 16px;
				font-weight: 100;
			}
		}
		.ptih-title {
			background-color: rgba(0, 0, 0, 0.1);
			padding: 8px 10px 9px;
			margin: 0 -10px;
			position: absolute;
			width: 100%;
			bottom: 0;
			border-bottom: 0;
			text-transform: uppercase;
		}
	}
	.pti-body .ptib-item {
		&:not(:last-child) {
			border-bottom: 1px solid #eee;
		}
		padding: 15px 0;
		font-weight: 400;
	}
	.pti-footer {
		padding: 20px;
		border-top: 0 !important;
	}
}


/*--pricing style02--*/


/*--pricing style04--*/

.pricing-tabs {
	.pri-tabs-heading {
		padding: 15px;
		text-align: center;
		margin: 0 auto;
		display: inline-table;
	}
	.nav-price li a {
		padding: 12px 30px 12px 30px;
		color: #3c4858;
		border-radius: 30px;
		margin: 2px;
	}
	.tab-content {
		padding: 15px;
		margin-top: 1.5rem;
	}
	.pricing1 .list-unstyled li {
		border-bottom: 1px solid #eff2f7;
		&:first-child {
			border-top: 1px solid #eff2f7;
		}
	}
}


/*end*/