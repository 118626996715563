/*----- Buttons ------*/

button {
	border-radius: 0;
	&:focus {
		outline: 1px dotted;
		outline: 5px auto -webkit-focus-ring-color;
	}
	&.close {
		padding: 0;
		background-color: transparent;
		border: 0;
		-webkit-appearance: none;
	}
	&#ihavecookiesBtn {
		margin-left: 0px !important;
	}
}
.btn-primary {
	color: $white;
	background-color: $primary;
	border-color: $primary;
	&:hover {
		color: $white !important;
		background-color: #266bfb;
		border-color: #266bfb;
	}
	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(66, 91, 214, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: $primary;
		border-color: $primary;
	}
	&:not(:disabled):not(.disabled) {
		&:active,
		&.active {
			color: $white;
			background-color: #266bfb;
			border-color: #266bfb;
		}
	}
}

.show>.btn-primary.dropdown-toggle {
	color: $white;
	background-color: #266bfb;
	border-color: #266bfb;
}

.btn-primary:not(:disabled):not(.disabled) {
	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(66, 91, 214, 0.1);
	}
}

.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(66, 91, 214, 0.1);
}

.btn-secondary {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
	&:hover {
		color: $white !important;
		background-color: #05bae2;
		border-color: $secondary;
	}
	&:focus,
	&.focus {
		background-color: #05bae2;
		border-color: $secondary;
	}
	&:not(:disabled):not(.disabled) {
		&:active,
		&.active {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
		}
	}
	
}
.btn-secondary.disabled, .btn-secondary:disabled {
	color: #fff;
	background-color: $secondary;
	border-color: $secondary ; 
}
.show>.btn-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}

.btn-secondary:not(:disabled):not(.disabled) {
	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px $secondary;
		background-color: $secondary;
		border-color: $secondary;
	}
}

.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px $secondary;
}

.btn-outline-primary {
	color: $primary !important;
	background-color: transparent;
	background-image: none;
	border-color: $primary !important;
	&:hover {
		color: $white !important;
		background-color: $primary;
		border-color: $primary;
	}
	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(5, 117, 230, 0.1);
	}
	&.disabled,
	&:disabled {
		color: $primary;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active,
		&.active {
			color: $white;
			background-color: $primary;
			border-color: $primary;
		}
	}
}

.show>.btn-outline-primary.dropdown-toggle {
	color: $white !important;
	background-color: $primary;
	border-color: $primary;
}

.btn-outline-primary:not(:disabled):not(.disabled) {
	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(5, 117, 230, 0.1);
	}
}

.show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(5, 117, 230, 0.1);
}

.btn-outline-secondary {
	color: $secondary;
	background-color: transparent;
	background-image: none;
	border-color: $secondary !important;
	&:hover {
		color: $white !important;
		background-color: $secondary;
		border-color: $secondary;
	}
	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.1);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active,
		&.active {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
		}
	}
}

.show>.btn-outline-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}

.btn-outline-secondary:not(:disabled):not(.disabled) {
	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.1);
	}
}

.show>.btn-outline-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.1);
}

.btn-link {
	font-weight: 400;
	color: $primary;
	background-color: transparent;
	&:hover {
		color: $primary;
		text-decoration: underline !important;
		background-color: transparent;
		border-color: transparent;
	}
	&:focus,
	&.focus {
		text-decoration: underline;
		border-color: transparent;
		box-shadow: none;
	}
	&:disabled,
	&.disabled {
		color: #aaadb2;
		pointer-events: none;
	}
}

.eff-1,
.eff-2,
.eff-3,
.eff-4,
.eff-5,
.eff-6 {
	background: $primary;
}

.eff-7 {
	border: 0px solid $primary;
}

.button-1:hover .eff-7,
.eff-8 {
	border: 70px solid $primary;
}


.btn-primary-light {
	border-color: #dfd7fa;
	background-color: rgba(66, 91, 214, 0.25) !important;
	color: $primary !important;
	&:hover {
		border-color: $primary;
		background-color: $primary !important;
		color: $white !important;
	}
}

.btn-info-light {
	color: #0a7ffb;
	background: rgba(33, 150, 243, 0.24);
	border-color: #cae6fc;
	&:hover {
		color: $white;
		background-color: #0a7ffb;
		border-color: #0a7ffb;
	}
}

.btn-secondary-light {
	color: $secondary;
	background-color: rgba(2, 195 ,238, 0.25);
	border-color:  $secondary;
	&:hover {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	}
}

.btn-success-light {
	color: $success;
	background-color: #d1f3e0;
	border-color: #cdecdf;
	&:hover {
		color: $white;
		background-color: $success;
		border-color: $success;
	}
}

.btn-warning-light {
	background-color: $white;
	color: #ffa001;
}

.button-1 {
	width: 140px;
	text-align: center;
	cursor: pointer;
	position: relative;
	box-sizing: border-box;
	overflow: hidden;
	border: 1px solid $primary !important;
	a {
		font-size: 14px;
		text-decoration: none;
		line-height: 35px;
		transition: all .5s ease;
		z-index: 2;
		position: relative;
		color: $primary;
	}
	&:hover a {
		color: $white;
		text-decoration: none;
	}
	&:hover .eff-8 {
		border: 0px solid $primary;
	}
	&.btn-hover:hover a {
		color: $primary;
	}
}

.eff-1 {
	width: 140px;
	height: 50px;
	top: -2px;
	right: -140px;
	position: absolute;
	transition: all .5s ease;
	z-index: 1;
}

.button-1:hover .eff-1 {
	right: 0;
}

.eff-2 {
	width: 140px;
	height: 50px;
	top: -50px;
	right: 0;
	position: absolute;
	transition: all .5s ease;
	z-index: 1;
}

.button-1:hover .eff-2 {
	top: 0;
}

.eff-3 {
	width: 140px;
	height: 50px;
	bottom: -50px;
	right: 0;
	position: absolute;
	transition: all .5s ease;
	z-index: 1;
}

.button-1:hover .eff-3 {
	bottom: 0;
}

.eff-4 {
	width: 140px;
	height: 50px;
	left: -140px;
	top: 0;
	position: absolute;
	transition: all .5s ease;
	z-index: 1;
}

.button-1:hover .eff-4 {
	left: 0;
}

.eff-5 {
	width: 140px;
	height: 50px;
	left: -140px;
	top: -50px;
	position: absolute;
	transition: all .5s ease;
	z-index: 1;
}

.button-1:hover .eff-5 {
	left: 0;
	top: 0;
}

.eff-6 {
	width: 140px;
	height: 50px;
	right: -140px;
	bottom: -50px;
	position: absolute;
	transition: all .5s ease;
	z-index: 1;
}

.button-1:hover .eff-6 {
	right: 0;
	bottom: 0;
}

.button-6:hover a {
	color: $white;
}

.btn-overlay span {
	display: block;
	margin: 0.5rem;
	font-size: 16px;
	font-weight: 600;
}

.btn-badges .btn-lg {
	padding: .375rem .75rem !important;
}

.btn.dropdown-toggle {
	&.btn-outline-primary~.dropdown-menu .dropdown-plus-title {
		border-color: rgb(5, 117, 230) !important;
	}
	&.btn-outline-success~.dropdown-menu .dropdown-plus-title {
		border-color: rgb(76, 174, 76) !important;
	}
	&.btn-outline-info~.dropdown-menu .dropdown-plus-title {
		border-color: rgb(70, 184, 218) !important;
	}
	&.btn-outline-warning~.dropdown-menu .dropdown-plus-title {
		border-color: rgb(238, 162, 54) !important;
	}
	&.btn-outline-danger~.dropdown-menu .dropdown-plus-title {
		border-color: rgb(212, 63, 58) !important;
	}
}

button#gdpr-cookie-advanced {
	margin-left: 4px;
}

.showmore-button {
	text-align: center;
}

.showmore-button-inner {
	display: inline-block;
	padding: 10px 19px;
	line-height: 14px;
	font-size: 14px;
	border-radius: 25px;
	text-decoration: none;
	cursor: pointer;
	margin-top: 20px;
}

.showmore-button1 {
	text-align: center;
	.showmore-button1-inner {
		display: inline-block;
		line-height: 14px;
		font-size: 14px;
		color: #8c98b9;
		text-decoration: none;
		cursor: pointer;
		margin-top: 8px;
		margin-bottom: 20px;
	}
}

button, input {
	overflow: visible;
}
button, select {
	text-transform: none;
}
button, html [type="button"], [type="reset"], [type="submit"] {
	-webkit-appearance: button;
}
button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

/*-------- Buttons ------*/

.btn {
	display: inline-block;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 0.9375rem;
	line-height: 1.84615385;
	border-radius: 4px;
	transition:none;
	font-size: 14px;
    padding: .375rem .75rem;
    letter-spacing: .4px;
}
@media screen and (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}
.btn:hover, .btn:focus {
	text-decoration: none;
}
.btn:focus, .btn.focus {
	outline: 0;
	box-shadow: none !important;
}
.btn.disabled, .btn:disabled {
	opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
	background-image: none;
}
a.btn.disabled, fieldset:disabled a.btn {
	pointer-events: none;
}
.btn-default {
	color: $color;
	background: $background;
	border-color: $border;
}
.btn-default:hover {
	color: $color;
	background-color: #e7ebf5;
	border-color: #e7ebf5;
}
.btn-default:focus, .btn-default.focus {
	box-shadow: 0 0 0 2px rgb(229, 240, 250);
}
.btn-default.disabled, .btn-default:disabled {
	color: $color;
	background: $background;
	border-color: $border;
}
.btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active, .show>.btn-default.dropdown-toggle {
	color: $color;
	background: $background;
	border-color: $border;
}
.btn-default:not(:disabled):not(.disabled):active:focus, .btn-default:not(:disabled):not(.disabled).active:focus, .show>.btn-default.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(229, 240, 250, 0.9);
}
.btn-success {
	color: $white !important;
	background-color:$success;
	border-color:$success;
}
.btn-success:hover {
	color: $white !important;
	background-color: #03a066;
	border-color: #03a066;
}
.btn-success:focus, .btn-success.focus {
	box-shadow: 0 0 0 2px rgba(4 ,179 ,114, 0.1);
}
.btn-success.disabled, .btn-success:disabled {
	color: $white;
	background-color:$success;
	border-color:$success;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show>.btn-success.dropdown-toggle {
	color: $white  !important;
	background-color: #03a066;
	border-color: #03a066;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show>.btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(4 ,179 ,114, 0.1);
}
.btn-info {
	color: $white !important;
	background-color: $info;
	border-color: $info;
}
.btn-info:hover {
	color: $white !important;
	background-color: #189ab7;
	border-color: #189ab7;
}
.btn-info:focus, .btn-info.focus {
	box-shadow: 0 0 0 2px rgba(26, 168, 199, 0.1);
}
.btn-info.disabled, .btn-info:disabled {
	color: $white;
	background-color: $info;
	border-color: $info;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show>.btn-info.dropdown-toggle {
	color: $white;
	background-color:$info;
	border-color: $info;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show>.btn-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(26, 168, 199, 0.1);
}
.btn-warning {
	color: $white;
	background-color: $warning;
	border-color: $warning;
}
.btn-warning:hover {
	color: $white !important;
	background-color: #df9809;
	border-color: #df9809;
}
.btn-warning:focus, .btn-warning.focus {
	box-shadow: 0 0 0 2px rgba(242, 159, 3, 0.1);
	color: $white !important;
}
.btn-warning.disabled, .btn-warning:disabled {
	color: $white;
	background-color:#df9809;
	border-color:#df9809;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show>.btn-warning.dropdown-toggle {
	color: $white;
	background-color: #ffb113;
	border-color: #ffb113;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show>.btn-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(242, 159, 3, 0.1);
}
.btn-danger {
	color: $white;
	background-color:$danger;
	border-color:$danger;
}
.btn-danger:hover {
	color: $white !important;
	background-color: #f7152b;
	border-color: #f7152b;
}
.btn-danger:focus, .btn-danger.focus {
	box-shadow: 0 0 0 2px rgba(247, 21, 43, 0.1);
}
.btn-danger.disabled, .btn-danger:disabled {
	color: $white;
	background-color:$danger;
	border-color:$danger;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show>.btn-danger.dropdown-toggle {
	color: $white;
	background-color: #f7152b;
	border-color: #f7152b;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show>.btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(247, 21, 43, 0.1);
}
.btn-light {
	color: #3d4e67;
	background-color:$white;
	border-color:  $border;
}
.btn-light:hover {
    color: #282d4d !important;
    background-color: #ebf1ff !important;
    border-color: #ebf1ff !important;
}
.btn-light:focus, .btn-light.focus {
	box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	background-color: #ebf1ff !important;
    border-color: #ebf1ff !important;
}
.btn-light.disabled, .btn-light:disabled {
	color: #3d4e67;
	background-color:$white;
	border-color:  $border;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show>.btn-light.dropdown-toggle {
	color: #3d4e67;
	background-color: #ebf1ff;
	border-color: #ebf1ff;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show>.btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}
.btn-dark {
	color: $white;
	background-color: #272b44 !important;
	border-color: #272b44;
	box-shadow: 0 2px 5px 0 rgba(39, 43, 68, 0.3);
}
.btn-dark:hover {
	color: $white !important;
	background-color:#3d3f4e !important;
	border-color: #3d3f4e !important;
}
.btn-dark:focus, .btn-dark.focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}
.btn-dark.disabled, .btn-dark:disabled {
	color: $white;
	background-color: #3d3f4e;
	border-color: #3d3f4e;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show>.btn-dark.dropdown-toggle {
	color: $white;
	background-color: #555769;
	border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show>.btn-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}
.btn-outline-success {
	color:$success !important;
	background-color: transparent;
	background-image: none;
	border-color:$success;
}
.btn-outline-success:hover {
	color: $white !important;
	background-color:$success;
	border-color:$success;
}
.btn-outline-success:focus, .btn-outline-success.focus {
	box-shadow: 0 0 0 2px rgba(4 ,179 ,114, 0.1);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
	color:$success;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show>.btn-outline-success.dropdown-toggle {
	color: $white !important;
	background-color:$success;
	border-color:$success;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(4 ,179 ,114, 0.1);
}
.btn-outline-info {
	color: #00d6e6 !important;
	background-color: transparent;
	background-image: none;
	border-color: #00d6e6;
}
.btn-outline-info:hover {
	color: $white !important;
	background-color: #00d6e6;
	border-color: #00d6e6;
}
.btn-outline-info:focus, .btn-outline-info.focus {
	box-shadow: 0 0 0 2px rgba(26, 168, 199, 0.1);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
	color: #00d6e6;
	background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show>.btn-outline-info.dropdown-toggle {
	color: $white !important;
	background-color: #00d6e6;
	border-color: #00d6e6;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(26, 168, 199, 0.1);
}
.btn-outline-warning {
	color:$warning !important;
	background-color: transparent;
	background-image: none;
	border-color:$warning;
}
.btn-outline-warning:hover {
	color: $white !important;
	background-color:$warning;
	border-color:$warning;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
	box-shadow: 0 0 0 2px rgba(242, 159, 3, 0.1);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
	color:$warning;
	background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show>.btn-outline-warning.dropdown-toggle {
	color: $white !important;
	background-color:$warning;
	border-color:$warning;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(242, 159, 3, 0.1);
}
.btn-outline-danger {
	color:$danger !important;
	background-color: transparent;
	background-image: none;
	border-color:$danger;
}
.btn-outline-danger:hover {
	color: $white !important;
	background-color:$danger;
	border-color:$danger;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
	box-shadow: 0 0 0 2px rgba(247, 21, 43, 0.1);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
	color:$danger;
	background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show>.btn-outline-danger.dropdown-toggle {
	color: $white !important;
	background-color:$danger;
	border-color:$danger;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(247, 21, 43, 0.1);
}
.btn-outline-light {
	color: #3d4e67;
	background-color: transparent;
	background-image: none;
	border-color: #f8f9fa;
}
.btn-outline-light:hover {
	color: #3d4e67 !important;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
	box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
	color: #f8f9fa;
	background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show>.btn-outline-light.dropdown-toggle {
	color: #3d4e67;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}
.btn-outline-dark {
	color: #3d3f4e;
	background-color: transparent;
	background-image: none;
	border-color: #3d3f4e;
}
.btn-outline-dark:hover {
	color: $white;
	background-color: #3d3f4e;
	border-color: #3d3f4e;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
	color: #3d3f4e;
	background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show>.btn-outline-dark.dropdown-toggle {
	color: $white;
	background-color: #3d3f4e;
	border-color: #3d3f4e;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show>.btn-outline-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}
.btn-lg, .btn-group-lg>.btn {
	padding: 0.5rem 1rem;
	font-size: 1.125rem;
	line-height: 1.625;
	border-radius: 3px;
	font-size: 1.09rem;
	min-width: 2.75rem;
	font-weight: 400;
	padding:.475rem 2.7rem;
}
.btn-sm, .btn-group-sm>.btn {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	border-radius: 3px;
	padding: .2rem .7rem;
    font-size: .7rem;
}
.btn-block {
	display: block;
	width: 100%;
}
.btn-block+.btn-block {
	margin-top: 0.5rem;
}
/*----- Btn styles------*/

.btn-icon {
	padding-left: .8rem;
	padding-right: .8rem;
	text-align: center;
}
.btn.btn-app {
    position: relative;
    padding: 15px 5px;
    margin: 0 0 10px 10px;
    min-width: 80px;
    height: 60px;
    box-shadow: none;
    border-radius: 5px;
    text-align: center;
    color: #8f8794;
    border: 1px solid $border;
    background-color:#e5e8ef;
    font-size: 12px
}
.btn.btn-app>.fa,
.btn.btn-app>.glyphicon,
.btn.btn-app>.ion {
    font-size: 20px;
    display: block
}
.btn.btn-app:hover {
    background:#e5e8ef;
    color: #3f5068;
    border-color:  #eff2f7;
}
.btn.btn-app:active,
.btn.btn-app:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}
.btn.btn-app>.badge {
    position: absolute;
    top: -3px;
    right: -10px;
    font-weight: 400
}
/*.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px#510fa8;
}*/
.btn-pill {
	border-radius: 10rem;
	padding-left: 1.5em;
	padding-right: 1.5em;
}

.btn-square {
	border-radius: 0;
}
.btn-facebook {
	color: $white;
	background-color: #4777da !important;
	border-color: #4777da !important;
}
.btn-facebook:hover {
	color: $white !important;
	background-color: #3c6ed4 !important;
	border-color: #3c6ed4 !important;
}
.btn-facebook.active {
	color: $white !important;
	background-color:#4777da;
	border-color: #4777da;
}
.btn-facebook:focus, .btn-facebook.focus {
	box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
}
.btn-facebook.disabled, .btn-facebook:disabled {
	color: $white;
	background-color: #4777da !important;
	border-color: #4777da !important;
}
.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active, .show>.btn-facebook.dropdown-toggle {
	color: $white;
	background-color:#4777da;
	border-color: #4777da;
}
.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus, .show>.btn-facebook.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
}
.btn-twitter {
	color: $white;
	background-color: #1da1f3 !important;
	border-color: #1da1f3 !important;
}
.btn-twitter:hover {
	color: $white !important;
	background-color: #0d8ddc !important;
	border-color: #0c85d0 !important;
}
.btn-twitter:focus, .btn-twitter.focus {
	box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
}
.btn-twitter.disabled, .btn-twitter:disabled {
	color: $white;
	background-color: #1da1f3;
	border-color: #1da1f3;
}
.btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active, .show>.btn-twitter.dropdown-toggle {
	color: $white;
	background-color: #0c85d0;
	border-color: #0b7ec4;
}
.btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus, .show>.btn-twitter.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
}
.btn-google {
	color: $white;
	background-color:#ea4335 !important;
	border-color:#ea4335 !important;
}
.btn-google.active {
	color: $white;
	background-color: #d04237;
	border-color: #d04237;
}
.btn-google:hover {
	color: $white !important;
	background-color: #d04237 !important;
	border-color: #d04237 !important;
}
.btn-google:focus, .btn-google.focus {
	box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
}
.btn-google.disabled, .btn-google:disabled {
	color: $white;
	background-color:#d04237;
	border-color: #d04237;
}
.btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active, .show>.btn-google.dropdown-toggle {
	color: $white;
	background-color: #d04237;
	border-color: #d04237;
}
.btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus, .show>.btn-google.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
}
.btn-youtube {
	color: $white;
	background-color: #f60002 !important;
	border-color: #f60002 !important;
}
.btn-youtube:hover {
	color: $white !important;
	background-color: #d90000 !important;
	border-color: #cc0000 !important;
}
.btn-youtube:focus, .btn-youtube.focus {
	box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
}
.btn-youtube.disabled, .btn-youtube:disabled {
	color: $white;
	background-color: #f60002;
	border-color: #f60002;
}
.btn-youtube:not(:disabled):not(.disabled):active, .btn-youtube:not(:disabled):not(.disabled).active, .show>.btn-youtube.dropdown-toggle {
	color: $white;
	background-color: #cc0000;
	border-color: #bf0000;
}
.btn-youtube:not(:disabled):not(.disabled):active:focus, .btn-youtube:not(:disabled):not(.disabled).active:focus, .show>.btn-youtube.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
}
.btn-vimeo {
	color: $white;
	background-color: #1ab7ea !important;
	border-color: #1ab7ea !important;
}
.btn-vimeo:hover {
	color: $white !important;
	background-color: #1eb1e3 !important;
	border-color: #1eb1e3 !important;
}
.btn-vimeo:focus, .btn-vimeo.focus {
	box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
}
.btn-vimeo.disabled, .btn-vimeo:disabled {
	color: $white;
	background-color: #1ab7ea;
	border-color: #1ab7ea;
}
.btn-vimeo:not(:disabled):not(.disabled):active, .btn-vimeo:not(:disabled):not(.disabled).active, .show>.btn-vimeo.dropdown-toggle {
	color: $white;
	background-color: #1eb1e3;
	border-color: #108cb4;
}
.btn-vimeo:not(:disabled):not(.disabled):active:focus, .btn-vimeo:not(:disabled):not(.disabled).active:focus, .show>.btn-vimeo.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
}
.btn-dribbble {
	color: $white;
	background-color: #e04b85 !important;
	border-color: #e04b85 !important;
}
.btn-dribbble:hover {
	color: $white !important;
	background-color: #e62a72 !important;
	border-color: #e51e6b !important;
}
.btn-dribbble:focus, .btn-dribbble.focus {
	box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
}
.btn-dribbble.disabled, .btn-dribbble:disabled {
	color: $white;
	background-color: #ea4c89;
	border-color: #ea4c89;
}
.btn-dribbble:not(:disabled):not(.disabled):active, .btn-dribbble:not(:disabled):not(.disabled).active, .show>.btn-dribbble.dropdown-toggle {
	color: $white;
	background-color: #e51e6b;
	border-color: #dc1a65;
}
.btn-dribbble:not(:disabled):not(.disabled):active:focus, .btn-dribbble:not(:disabled):not(.disabled).active:focus, .show>.btn-dribbble.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
}
.btn-github {
	color: $white;
	background-color: #181717 !important;
	border-color: #181717 !important;
}
.btn-github:hover {
	color: $white !important;
	background-color: #040404;
	border-color: black;
}
.btn-github:focus, .btn-github.focus {
	box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
}
.btn-github.disabled, .btn-github:disabled {
	color: $white;
	background-color: #181717;
	border-color: #181717;
}
.btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active, .show>.btn-github.dropdown-toggle {
	color: $white;
	background-color: black;
	border-color: black;
}
.btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus, .show>.btn-github.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
}
.btn-instagram {
	color: $white;
	background-color: #e4405f !important;
	border-color: #e4405f !important;
}
.btn-instagram:hover {
	color: $white !important;
	background-color: #de1f44;
	border-color: #d31e40;
}
.btn-instagram:focus, .btn-instagram.focus {
	box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
}
.btn-instagram.disabled, .btn-instagram:disabled {
	color: $white;
	background-color: #e4405f;
	border-color: #e4405f;
}
.btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active, .show>.btn-instagram.dropdown-toggle {
	color: $white;
	background-color: #d31e40;
	border-color: #c81c3d;
}
.btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus, .show>.btn-instagram.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
}
.btn-pinterest {
	color: $white;
	background-color: #bd081c !important;
	border-color: #bd081c !important;
}
.btn-pinterest:hover {
	color: $white !important;
	background-color: #980617 !important;
	border-color: #df2a31 !important;
}
.btn-pinterest:focus, .btn-pinterest.focus {
	box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
}
.btn-pinterest.disabled, .btn-pinterest:disabled {
	color: $white;
	background-color: #bd081c;
	border-color: #bd081c;
}
.btn-pinterest:not(:disabled):not(.disabled):active, .btn-pinterest:not(:disabled):not(.disabled).active, .show>.btn-pinterest.dropdown-toggle {
	color: $white;
	background-color: #df2a31;
	border-color: #df2a31;
}
.btn-pinterest:not(:disabled):not(.disabled):active:focus, .btn-pinterest:not(:disabled):not(.disabled).active:focus, .show>.btn-pinterest.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
}
.btn-vk {
	color: $white;
	background-color: #6383a8 !important;
	border-color: #6383a8 !important;
}
.btn-vk:hover {
	color: $white;
	background-color: #527093;
	border-color: #4d6a8b;
}
.btn-vk:focus, .btn-vk.focus {
	box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
}
.btn-vk.disabled, .btn-vk:disabled {
	color: $white;
	background-color: #6383a8;
	border-color: #6383a8;
}
.btn-vk:not(:disabled):not(.disabled):active, .btn-vk:not(:disabled):not(.disabled).active, .show>.btn-vk.dropdown-toggle {
	color: $white;
	background-color: rgb(18, 15, 99);
	border-color: #496482;
}
.btn-vk:not(:disabled):not(.disabled):active:focus, .btn-vk:not(:disabled):not(.disabled).active:focus, .show>.btn-vk.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
}
.btn-rss {
	color: $white;
	background-color: #ffa500;
	border-color: #ffa500;
}
.btn-rss:hover {
	color: $white;
	background-color: #d98c00;
	border-color: #cc8400;
}
.btn-rss:focus, .btn-rss.focus {
	box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
}
.btn-rss.disabled, .btn-rss:disabled {
	color: $white;
	background-color: #ffa500;
	border-color: #ffa500;
}
.btn-rss:not(:disabled):not(.disabled):active, .btn-rss:not(:disabled):not(.disabled).active, .show>.btn-rss.dropdown-toggle {
	color: $white;
	background-color: #cc8400;
	border-color: #bf7c00;
}
.btn-rss:not(:disabled):not(.disabled):active:focus, .btn-rss:not(:disabled):not(.disabled).active:focus, .show>.btn-rss.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
}
.btn-flickr {
	color: $white;
	background-color: #0063dc;
	border-color: #0063dc;
}
.btn-flickr:hover {
	color: $white;
	background-color: #0052b6;
	border-color: #004ca9;
}
.btn-flickr:focus, .btn-flickr.focus {
	box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
}
.btn-flickr.disabled, .btn-flickr:disabled {
	color: $white;
	background-color: #0063dc;
	border-color: #0063dc;
}
.btn-flickr:not(:disabled):not(.disabled):active, .btn-flickr:not(:disabled):not(.disabled).active, .show>.btn-flickr.dropdown-toggle {
	color: $white;
	background-color: #004ca9;
	border-color: #00469c;
}
.btn-flickr:not(:disabled):not(.disabled):active:focus, .btn-flickr:not(:disabled):not(.disabled).active:focus, .show>.btn-flickr.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
}
.btn-bitbucket {
	color: $white;
	background-color: #0052cc;
	border-color: #0052cc;
}
.btn-bitbucket:hover {
	color: $white;
	background-color: #0043a6;
	border-color: #003e99;
}
.btn-bitbucket:focus, .btn-bitbucket.focus {
	box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
}
.btn-bitbucket.disabled, .btn-bitbucket:disabled {
	color: $white;
	background-color: #0052cc;
	border-color: #0052cc;
}
.btn-bitbucket:not(:disabled):not(.disabled):active, .btn-bitbucket:not(:disabled):not(.disabled).active, .show>.btn-bitbucket.dropdown-toggle {
	color: $white;
	background-color: #003e99;
	border-color: #00388c;
}
.btn-bitbucket:not(:disabled):not(.disabled):active:focus, .btn-bitbucket:not(:disabled):not(.disabled).active:focus, .show>.btn-bitbucket.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
}
.btn-blue {
	color: $white;
	background-color: $black;
	border-color: $black;
}
.btn-blue:hover {
	color: $white !important; 
	background-color: #0b0ba7;
	border-color: #0b0ba7;
}
.btn-blue:focus, .btn-blue.focus {
	box-shadow: 0 0 0 2px rgba(5, 117, 230, 0.5);
}
.btn-blue.disabled, .btn-blue:disabled {
	color: $white;
	background-color: $black;
	border-color: $black;
}
.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active, .show>.btn-blue.dropdown-toggle {
	color: $white;
	background-color: #2861b1;
	border-color: #2c60a9;
}
.btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus, .show>.btn-blue.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(5, 117, 230, 0.5);
}
.btn-indigo {
	color: $white;
	background-color: $indigo;
	border-color: $indigo;
}
.btn-indigo:hover {
	color: $white !important;
	background-color: #5a2f86;
	border-color: #5a2f86;
}
.btn-indigo:focus, .btn-indigo.focus {
	box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
}
.btn-indigo.disabled, .btn-indigo:disabled {
	color: $white;
	background-color: $indigo;
	border-color: $indigo;
}
.btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active, .show>.btn-indigo.dropdown-toggle {
	color: $white;
	background-color: #3f51c1;
	border-color: #3b4db7;
}
.btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus, .show>.btn-indigo.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
}
.btn-purple {
	color: $white;
	background-color: $purple !important;
	border-color: $purple !important;
}
.btn-purple:hover {
	color: $white !important;
	background-color: #7d0bb1 !important;
	border-color: #7d0bb1 !important;
}
.btn-purple:focus, .btn-purple.focus {
	box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
}
.btn-purple.disabled, .btn-purple:disabled {
	color: $white;
	background-color: #7d0bb1;
	border-color: #7d0bb1;
}
.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active, .show>.btn-purple.dropdown-toggle {
	color: $white;
	background-color: #8c31e4;
	border-color: #8526e3;
}
.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus, .show>.btn-purple.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
}
.btn-pink {
	color: $white;
	background-color: $pink !important;
	border-color: $pink !important;
	box-shadow: 0 5px 10px rgba(244, 63, 134, 0.3);
}
.btn-pink:hover {
	color: $white !important;
	background-color: #e61470 !important;
	border-color: #e61470 !important;
}
.btn-pink:focus, .btn-pink.focus {
	box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
}
.btn-pink.disabled, .btn-pink:disabled {
	color: $white;
	background-color: $pink;
	border-color: $pink;
}
.btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active, .show>.btn-pink.dropdown-toggle {
	color: $white;
	background-color: #e61470;
	border-color: #e61470;
}
.btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus, .show>.btn-pink.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
}
.btn-red {
	color: $white;
	background-color:#ff0000 !important;
	border-color:#ff0000 !important;
}
.btn-red:hover {
	color: $white !important;
	background-color: #f7152b !important;
	border-color: #f7152b !important;
}
.btn-red:focus, .btn-red.focus {
	box-shadow: 0 0 0 2px rgba(247, 21, 43, 0.5);
}
.btn-red.disabled, .btn-red:disabled {
	color: $white;
	background-color:$danger;
	border-color:$danger;
}
.btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active, .show>.btn-red.dropdown-toggle {
	color: $white;
	background-color: #f7152b;
	border-color: #f7152b;
}
.btn-red:not(:disabled):not(.disabled):active:focus, .btn-red:not(:disabled):not(.disabled).active:focus, .show>.btn-red.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(247, 21, 43, 0.5);
}
.btn-orange {
	color: $white;
	background-color: $orange;
	border-color: $orange;
}
.btn-orange:hover {
	color: $white !important;
	background-color: #e85803 !important;
	border-color: #e85803 !important;
}
.btn-orange:focus, .btn-orange.focus {
	box-shadow: 0 0 0 2px rgba(232, 88, 3, 0.5);
}
.btn-orange.disabled, .btn-orange:disabled {
	color: $white;
	background-color: $orange;
	border-color: $orange;
}
.btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active, .show>.btn-orange.dropdown-toggle {
	color: $white;
	background-color: #e85803;
	border-color: #e85803;
}
.btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus, .show>.btn-orange.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(232, 88, 3, 0.5);
}
.btn-yellow {
	color: $white;
	background-color:#ffaa15 !important;
	border-color:#ffaa15 !important;
}
.btn-yellow:hover {
	color: $white !important;
	background-color:#e69506 !important;
	border-color:#e69506 !important;
}
.btn-yellow:focus, .btn-yellow.focus {
	box-shadow: 0 0 0 2px rgba(242, 159, 3, 0.5);
}
.btn-yellow.disabled, .btn-yellow:disabled {
	color: $white;
	background-color:#e69506;
	border-color:#e69506;
}
.btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active, .show>.btn-yellow.dropdown-toggle {
	color: $white;
	background-color: #e69506;
	border-color: #e69506;
}
.btn-yellow:not(:disabled):not(.disabled):active:focus, .btn-yellow:not(:disabled):not(.disabled).active:focus, .show>.btn-yellow.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(242, 159, 3, 0.5);
}
.btn-green {
	color: $white;
	background-color: #008640 !important;
	border-color:#008640 !important;
}
.btn-green:hover {
	color: $white !important;
	background-color: #026f36 !important;
	border-color: #026f36;
}
.btn-green:focus, .btn-green.focus {
	box-shadow: 0 0 0 2px rgba(0, 134, 64, 0.5);
}
.btn-green.disabled, .btn-green:disabled {
	color: $white;
	background-color:#008640;
	border-color:#008640;
}
.btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active, .show>.btn-green.dropdown-toggle {
	color: $white;
	background-color: #008640;
	border-color: #008640;
}
.btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus, .show>.btn-green.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(0, 134, 64, 0.5);
}
.btn-teal {
	color: $white;
	background-color: $teal !important;
	border-color: $teal !important;
}
.btn-teal:hover {
	color: $white !important;
	background-color: #026f6f !important;
	border-color:#026f6f;
}
.btn-teal:focus, .btn-teal.focus {
	box-shadow: 0 0 0 2px rgba(0, 128, 128, 0.5);
}
.btn-teal.disabled, .btn-teal:disabled {
	color: $white;
	background-color: $teal;
	border-color: $teal;
}
.btn-teal:not(:disabled):not(.disabled):active, .btn-teal:not(:disabled):not(.disabled).active, .show>.btn-teal.dropdown-toggle {
	color: $white;
	background-color: #026f6f;
	border-color: #026f6f;
}
.btn-teal:not(:disabled):not(.disabled):active:focus, .btn-teal:not(:disabled):not(.disabled).active:focus, .show>.btn-teal.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(0, 128, 128, 0.5);
}
.btn-cyan {
	color: $white;
	background-color:$cyan !important;
	border-color:$cyan !important;
}
.btn-cyan:hover {
	color: $white !important;
	background-color: #35e0ff !important;
	border-color: #35e0ff !important;
}
.btn-cyan:focus, .btn-cyan.focus {
	box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
}
.btn-cyan.disabled, .btn-cyan:disabled {
	color: $white;
	background-color:$cyan;
	border-color:$cyan;
}
.btn-cyan:not(:disabled):not(.disabled):active, .btn-cyan:not(:disabled):not(.disabled).active, .show>.btn-cyan.dropdown-toggle {
	color: $white;
	background-color: #35e0ff;
	border-color: #10707f;
}
.btn-cyan:not(:disabled):not(.disabled):active:focus, .btn-cyan:not(:disabled):not(.disabled).active:focus, .show>.btn-cyan.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
}
.btn-white {
	color: #3d4e67 !important;
	background-color: $white;
	border-color:  #eff2f7;
}
.btn-white:hover {
	color: #3d4e67 !important;
	background-color: #fafbfe;
	border-color: #eff2f7;
}
.btn-white:focus, .btn-white.focus {
	box-shadow: 0 0 0 2px 5;
}
.btn-white.disabled, .btn-white:disabled {
	color: #3d4e67;
	background-color: $white;
	border-color: $white;
}
.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .show>.btn-white.dropdown-toggle {
	color: #3d4e67;
	background-color: #e9ecfb;
	border-color: #e9ecfb;
}
.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus, .show>.btn-white.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px 5;
}
.btn-gray {
	color: $white;
	background-color: #aaadb2;
	border-color: #aaadb2;
}
.btn-gray:hover {
	color: $white !important;
	background-color:$color;
	border-color: $color;
}
.btn-gray:focus, .btn-gray.focus {
	box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
}
.btn-gray.disabled, .btn-gray:disabled {
	color: $white;
	background-color: #aaadb2;
	border-color: #aaadb2;
}
.btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active, .show>.btn-gray.dropdown-toggle {
	color: $white;
	background-color: $color;
	border-color: #666e76;
}
.btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus, .show>.btn-gray.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
}
.btn-gray-dark {
	color: $white;
	background-color: $dark;
	border-color: $dark;
}
.btn-gray-dark:hover {
	color: $white !important;
	background-color:#555769;
	border-color: #555769;
}
.btn-gray-dark:focus, .btn-gray-dark.focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
}
.btn-gray-dark.disabled, .btn-gray-dark:disabled {
	color: $white;
	background-color: #3d3f4e;
	border-color: #3d3f4e;
}
.btn-gray-dark:not(:disabled):not(.disabled):active, .btn-gray-dark:not(:disabled):not(.disabled).active, .show>.btn-gray-dark.dropdown-toggle {
	color: $white;
	background-color: #555769;
	border-color: #171a1d;
}
.btn-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-gray-dark:not(:disabled):not(.disabled).active:focus, .show>.btn-gray-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
}
.btn-azure {
	color: $white;
	background-color:#0080ff;
	border-color:#0080ff;
}
.btn-azure:hover {
	color: $white !important;
	background-color: #208bf5;
	border-color: #208bf5;
}
.btn-azure:focus, .btn-azure.focus {
	box-shadow: 0 0 0 2px rgba(26, 168, 199, 0.5);
}
.btn-azure.disabled, .btn-azure:disabled {
	color: $white;
	background-color: #00d6e6;
	border-color: #00d6e6;
}
.btn-azure:not(:disabled):not(.disabled):active, .btn-azure:not(:disabled):not(.disabled).active, .show>.btn-azure.dropdown-toggle {
	color: $white;
	background-color: #1594ef;
	border-color: #108ee7;
}
.btn-azure:not(:disabled):not(.disabled):active:focus, .btn-azure:not(:disabled):not(.disabled).active:focus, .show>.btn-azure.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(26, 168, 199, 0.5);
}
.btn-lime {
	color: $white !important;
	background-color: #bfff00;
	border-color: #bfff00;
}
.btn-lime:hover {
	color: $white !important;
	background-color: #aade0e;
	border-color: #aade0e;
}
.btn-lime:focus, .btn-lime.focus {
	box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
}
.btn-lime.disabled, .btn-lime:disabled {
	color: $white;
	background-color: #bfff00;
	border-color: #bfff00;
}
.btn-lime:not(:disabled):not(.disabled):active, .btn-lime:not(:disabled):not(.disabled).active, .show>.btn-lime.dropdown-toggle {
	color: $white;
	background-color: #63ad27;
	border-color: #5da324;
}
.btn-lime:not(:disabled):not(.disabled):active:focus, .btn-lime:not(:disabled):not(.disabled).active:focus, .show>.btn-lime.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
}
.btn-option {
	background: transparent;
	color: #8f8794;
}
.btn-option:hover {
	color: #5f6877;
}
.btn-option:focus {
	box-shadow: none;
	color: #5f6877;
}
.btn-list {
	margin-bottom: -.5rem;
	font-size: 0;
}
.btn-list>.btn, .btn-list>.dropdown {
	margin-bottom: .5rem;
}
.btn-list>.btn:not(:last-child), .btn-list>.dropdown:not(:last-child) {
	margin-right: .5rem;
}
.btn-loading {
	color: transparent !important;
	pointer-events: none;
	position: relative;
}
.btn-loading:after {
	content: '';
	-webkit-animation: loader 500ms infinite linear;
	animation: loader 500ms infinite linear;
	border: 2px solid $white;
	border-radius: 50%;
	border-right-color: transparent !important;
	border-top-color: transparent !important;
	display: block;
	height: 1.4em;
	width: 1.4em;
	position: absolute;
	left: calc(50% - (1.4em / 2));
	top: calc(50% - (1.4em / 2));
	-webkit-transform-origin: center;
	transform-origin: center;
	position: absolute !important;
}
.btn-loading.btn-sm:after, .btn-group-sm>.btn-loading.btn:after {
	height: 1em;
	width: 1em;
	left: calc(50% - (1em / 2));
	top: calc(50% - (1em / 2));
}
.btn-loading.btn-secondary:after {
	border-color: #3d4e67;
}

.card-options a:not(.btn) {
	margin-left: .5rem;
	color: #b0bcd0;
	font-size:14px;
	display: inline-block;
	min-width: 1rem;
}
.card-options a:not(.btn):hover {
	text-decoration: none;
	color: #8f8794;
}
.card-options a:not(.btn) i {
	font-size: 1rem;
	vertical-align: middle;
}
.input-group-prepend>.btn, .input-group-append>.btn, .input-group-btn>.btn {
	height: 100%;
}
.btn-group, .btn-group-vertical {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle;
}
.btn-group>.btn, .btn-group-vertical>.btn {
	position: relative;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
}
.btn-group>.btn:hover, .btn-group-vertical>.btn:hover {
	z-index: 1;
}
.btn-group>.btn:focus, .btn-group>.btn:active, .btn-group>.btn.active, .btn-group-vertical>.btn:focus, .btn-group-vertical>.btn:active, .btn-group-vertical>.btn.active {
	z-index: 1;
}
.btn-group .btn+.btn, .btn-group .btn+.btn-group, .btn-group .btn-group+.btn, .btn-group .btn-group+.btn-group, .btn-group-vertical .btn+.btn, .btn-group-vertical .btn+.btn-group, .btn-group-vertical .btn-group+.btn, .btn-group-vertical .btn-group+.btn-group {
	margin-left: -1px;
}
.btn-toolbar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: start;
	justify-content: flex-start;
}
.btn-toolbar .input-group {
	width: auto;
}
.btn-group>.btn:first-child {
	margin-left: 0;
}
.dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
	margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
	margin-right: 0;
}
.btn-sm+.dropdown-toggle-split, .btn-group-sm>.btn+.dropdown-toggle-split {
	padding-right: 0.375rem;
	padding-left: 0.375rem;
}
.btn-lg+.dropdown-toggle-split, .btn-group-lg>.btn+.dropdown-toggle-split {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.btn-group-vertical {
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
}
.btn-group-vertical .btn, .btn-group-vertical .btn-group {
	width: 100%;
}
.btn-group-vertical>.btn+.btn, .btn-group-vertical>.btn+.btn-group, .btn-group-vertical>.btn-group+.btn, .btn-group-vertical>.btn-group+.btn-group {
	margin-top: -1px;
	margin-left: 0;
}
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical>.btn-group:not(:last-child)>.btn {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.btn-group-vertical>.btn:not(:first-child), .btn-group-vertical>.btn-group:not(:first-child)>.btn {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.btn-group-toggle>.btn, .btn-group-toggle>.btn-group>.btn {
	margin-bottom: 0;
}
.btn-group-toggle>.btn input[type="radio"], .btn-group-toggle>.btn input[type="checkbox"], .btn-group-toggle>.btn-group>.btn input[type="radio"], .btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}
.input-group>.input-group-prepend>.btn, .input-group>.input-group-prepend>.input-group-text, .input-group>.input-group-append:not(:last-child)>.btn, .input-group>.input-group-append:not(:last-child)>.input-group-text, .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle), .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.input-group>.input-group-append>.btn, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:not(:first-child)>.btn, .input-group>.input-group-prepend:not(:first-child)>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-1.btn-hover a{
	color:$white;
}
.pricing-table:hover>.panel>.panel-footer>.btn {
	border: solid 1px $white !important;
	-webkit-transition: all .3s ease;
}
.btn-price:hover {
	background: $white !important;
	color:#510fa8 !important;
	-webkit-transition: all .3s ease;
}
.panel.price .btn {
	box-shadow: 0 -1px 0px rgba(50, 50, 50, .2) inset;
	border: 0px;
}
.btn.dropdown-toggle~.dropdown-menu, ul.dropdown-menu li.dropdown ul.dropdown-menu {
	background-color: $white!important;
	border: 0 solid $border !important;
	box-shadow: 0px 3px 15px rgba(25, 25, 25, 0.3) !important;
	top: 0px !important;
	margin: 0px !important;
	padding: 0px !important;
}

.btn.dropdown-toggle.btn-primary~.dropdown-menu .dropdown-plus-title {
	border-color: rgb(5, 117, 230) !important;
}
.btn.dropdown-toggle.btn-success~.dropdown-menu .dropdown-plus-title {
	border-color: rgb(76, 174, 76) !important;
}
.btn.dropdown-toggle.btn-info~.dropdown-menu .dropdown-plus-title {
	border-color: rgb(70, 184, 218) !important;
}
.btn.dropdown-toggle.btn-warning~.dropdown-menu .dropdown-plus-title {
	border-color: rgb(238, 162, 54) !important;
}
.btn.dropdown-toggle.btn-danger~.dropdown-menu .dropdown-plus-title {
	border-color: rgb(212, 63, 58) !important;
}

.btn .badge {
	position: relative;
	top: -1px;
}
/*--loaders--*/
.spinner {
	background:#0245da ;
}
.spinner-lg {
	background-color: #0245da ;
}
.double-bounce1, .double-bounce2 {
	background: #0245da ;
}
.cube1, .cube2 {
	background: #0245da ;
}
.lds-heart div {
	background: #0245da ;
}
.lds-heart div:after,
.lds-heart div:before {
	background: #0245da ;
}
.lds-ring div {
	border: 6px solid #0245da ;
	border-color: #0245da  transparent transparent transparent;
}
.lds-hourglass:after {
	border: 26px solid #0245da;
    border-color: #0245da transparent;
}
.lds-circle > div {
	background: #0245da ;
}
.lds-dual-ring:after {
	border: 5px solid #0245da ;
	border-color: #0245da  transparent #0245da  transparent;
}
.lds-facebook div {
	background: #0245da ;
}
.lds-roller div:after {
	background: #0245da ;
}
.lds-ripple div {
	border: 4px solid #0245da ;
}
.lds-spinner div:after {
	background: #0245da ;
}
.lds-default div {
	background: #0245da ;
}
.lds-ellipsis div {
	background: #0245da ;
}
.lds-grid div {
	background: #0245da ;
}
div.lds-ball2 div {
	background: #0245da ;
}
.lds-ball div {
	background: #0245da ;
}
.lds-bars div:nth-child(1) {
	background: #0245da ;
}
.lds-bars div:nth-child(2) {
	background: #0245da ;
}
.lds-bars div:nth-child(3) {
	background: #0245da ;
}
.lds-bars div:nth-child(4) {
	background: #0245da ;
}
@keyframes lds-blocks {
	0% {
		background: $secondary;
	}
	12.5% {
		background: $secondary;
	}
	12.625% {
		background: #0245da ;
	}
	100% {
		background: #0245da ;
	}
}
@-webkit-keyframes lds-blocks {
	0% {
		background: $secondary;
	}
	12.5% {
		background: $secondary;
	}
	12.625% {
		background: #0245da ;
	}
	100% {
		background: #0245da ;
	}
}
.lds-blocks div {
	background: #0245da ;
}
.lds-cube div {
	background: #0245da ;
}
.lds-eclipse div {
	box-shadow: 0 4px 0 0 #0245da ;
}
.lds-flickr div:nth-child(1) {
	background: #0245da ;
}
.lds-flickr div:nth-child(3) {
	background: #0245da ;
}
.lds-gear>div div {
	background: #0245da ;
}
.lds-magnify>div>div div:nth-child(1) {
	border: 12px solid #0245da ;
}
.lds-magnify>div>div div:nth-child(2) {
	background: #0245da ;
}
.lds-pacman>div:nth-child(2) div {
	background: #0245da ;
}
.lds-disk>div div:nth-child(1) {
	background: #0245da ;
}
.bn-label{
	background: #0245da ;
	color:$white;
}
.best-ticker{
	border: solid 1px #0245da ;
}
.rating-stars .rating-stars-container .rating-star.is--active,
.rating-stars .rating-stars-container .rating-star.is--hover {
	color: #ffc107;
}
button, [type="button"], [type="reset"], [type="submit"] {
	-webkit-appearance: button;
}
.ripple {
	position: relative;
	overflow: hidden;
	transform: translate3d(0, 0, 0);
	cursor:pointer;
	resize: none;
    appearance: none;
}
.ripple:before {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
	background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
	background-repeat: no-repeat;
	background-position: 50%;
	transform: scale(10, 10);
	opacity: 0;
	transition: transform .5s, opacity 1s
}
.ripple:active:before {
	transform: scale(0, 0);
	opacity: .2;
	transition: 0s;
}
@keyframes fadein {
	from {
		opacity: 0;
		transform: translate3d(0, 20px, 0)
	}
	to {
		opacity: 1;
		transform: none;
		transform: translate3d(0, 0, 0)
	}
}
/*-----Br Themes-----*/

