/*----- Errorpages ------*/

.construction-image {
	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		z-index: 1;
		left: 0;
		right: 0;
	}
	.card {
		background: rgb(255, 255, 255);
	}
}


/*construction*/

.progress-info {
	display: flex;
	margin-bottom: .5rem;
	align-items: center;
	justify-content: space-between;
}

.progress-label span {
	font-size: .625rem;
	font-weight: 600;
	display: inline-block;
	padding: .25rem 1rem;
	text-transform: uppercase;
	border-radius: 30px;
	background: rgba(94, 114, 228, 0.1);
}

.progress-percentage {
	text-align: right;
	span {
		font-size: .875rem;
		font-weight: 600;
		display: inline-block;
		color: $white;
	}
}

.construction-limiter {
	position: relative;
}

.con-absolute {
	position: absolute;
	bottom: 20px;
	text-align: center;
	margin: 0 15px;
	left: 0;
	right: 0;
}


/*error pages*/

.floating {
	animation: floating 3s ease infinite;
	will-change: transform;
	&:hover {
		animation-play-state: paused;
	}
}

.floating-lg {
	animation: floating-lg 3s ease infinite;
}

.floating-sm {
	animation: floating-sm 3s ease infinite;
}

.error-bg {
	background-image: url('images/banners/banner7.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}

.errorpage {
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
	position: relative;
	height: 100vh;
	.error-content {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
}

.error-content {
	width: 100%;
	text-align: center;
	border-radius: 7px;
}

.error-page {
	position: relative;
	.display-1 {
		font-size: 10rem;
		font-weight:600;
		margin-top: 0px;
	}
	.btn{
		padding: 0.55rem 1rem;
		border-radius: 4px;
	}
}

.construction-img {
	background: url('images/other/construction.jpg');
	background-size: cover;
}