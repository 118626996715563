/*----- Tabsmenu ------*/

.tab-content> {
	.tab-pane {
		display: none;
	}
	.active {
		display: block;
	}
}

.emp-tab table {
	margin-bottom: 0;
	border: 0;
}

.tab_wrapper {
	>ul {
		li {
			border: 1px solid $border;
			border-top: 3px solid #eff2f7;
		}
		border-bottom: 1px solid $border;
	}
	&.right_side {
		.content_wrapper {
			border: 1px solid $border;
		}
		>ul {
			li {
				&.active {
					border-color: #eff2f7;
				}
				&:after {
					background: #eff2f7;
				}
			}
			border-bottom: 1px solid $border;
		}
	}
}

.tabs-menu ul {
	&.eductaional-tabs li a {
		padding: 10px 20px 11px 20px;
		background: $pink;
		border-radius: 23px;
		margin: 10px;
		color: $white;
	}
	li a {
		padding: 11px 14px 11px 14px;
	}
}

.tabs-menu1 ul li a {
	padding: 10px 20px 11px 20px;
}

.tabs-menu-body {
	padding: 15px;
	border: 1px solid $border;
	p:last-child {
		margin-bottom: 0;
	}
}

.tab-menu-heading {
	padding: 20px 8px;
	border: 1px solid $border;
	border-bottom: 0;
}

.tab_wrapper .content_wrapper .tab_content.active p:last-child {
	margin-bottom: 0;
}