/*----- Accordation ------*/

.accordion .card:not(:first-of-type):not(:last-of-type) {
	border-bottom: 0;
	border-radius: 0;
}
.accordion .card:not(:first-of-type) .card-header:first-child {
	border-radius: 0;
}
.accordion .card:first-of-type {
	border-bottom: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.accordion .card:last-of-type {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.accor h4 a {
    padding: .75rem 1.25rem;
    display: block;
	font-size: 15px;
    border-bottom: 1px solid $border;
}
.acc_section.acc_active h3{
	font-size:16px;
}
.demo-accordion p:last-child, .panel-group1 p:last-child{
	margin-bottom:0;
}
#accordion .panel-heading1 a:before {
	content: "\f01a";
	font-size: 24px;
	position: absolute;
	font-family: 'FontAwesome';
	right: 5px;
	top: 10px;
	transform: rotate(180deg);
	transition: all 0.5s;
}
#accordion .panel-heading1.active a:before {
	transform: rotate(0deg);
	transition: all 0.5s;
}
.accordion-wizard .badge.badge-pill{
	padding: .25em .4em;
	border-radius:3px;
}
.accor.bg-primary{
	background: #f4f7fe !important;
}
.accor.bg-primary h4 a {
	color: #171a29;
}
.accor {
	background:#edeff5;
}