/*----- Header ------*/

.aside-header {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid $border;
}
.hor-header {
	padding:.75rem;
}
.header {
	background: $white;
	width: 100%;
	z-index: 100;
	.dropdown-menu {
		margin-top: 0;
	}
	.mega-menu {
		width: 350px;
	}
	.nav-link .badge,
	.nav-item .badge {
		position: absolute;
		right: 20px;
		min-width: 1rem;
		font-size: 13px;
	}
	.nav-link .badge {
		position: absolute;
		top: -1px;
		left: 22px;
		display: block !important;
		padding: 3px 5px !important;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		font-size: 11px;
		line-height: 1;
	}
}

body.fixed-header .header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
}

@media print {
	.header {
		display: none;
	}
}

.header-right .header-notify  .dropdown-menu{
	min-width:18rem;
	border:0;
}
.header-brand {
	color: inherit;
    margin-right: 0;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2rem;
}

.app-header .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.6rem;
	min-width: 200px;
	text-align: center;
}

.card .app-header .header-brand {
	min-width: auto;
}

.app-header .header-brand .header-brand-img {
	margin-right: 0;
}

.app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 215px;
	text-align: center;
	margin-top: -1.5px;
	.header-brand-img {
		margin-right: 0;
	}
}

.header-brand:hover {
	color: inherit;
	text-decoration: none;
}

.header-brand-img {
    vertical-align: middle;
    margin-right: 0;
    width: auto;
}

.header-avatar {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	vertical-align: bottom;
	border-radius: 50%;
}

.header-btn {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	font-size: 1rem;
	&.has-new {
		position: relative;
		&:before {
			content: '';
			width: 6px;
			height: 6px;
			background: #f94859;
			position: absolute;
			top: 4px;
			right: 4px;
			border-radius: 50%;
		}
	}
}

.header-toggler {
	width: 2rem;
	height: 2rem;
	position: relative;
	color: $white;
	&:hover {
		color: $white;
	}
}

.header-toggler-icon {
	position: absolute;
	width: 1rem;
	height: 2px;
	color: inherit;
	background: currentColor;
	border-radius: 3px;
	top: 50%;
	left: 50%;
	margin: -2px 0 0 -.5rem;
	box-shadow: 0 5px currentColor, 0 -5px currentColor;
}
#headerMenuCollapse {
	.nav-link .badge,
	.nav-item .badge {
		position: relative;
		min-width: 1rem;
		font-size: 10px;
		font-weight: 500;
	}
}

.header-main-banner {
	position: absolute;
	top: 0;
	display: block;
	width: 100%;
	z-index: 99;
	.horizontal-main,
	.horizontalMenu>.horizontalMenu-list {
		background: transparent;
	}
}

.header-main .social-icons {
	float: right;
	li {
		color: #212529;
		margin-right: 22px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: $primary;
		}
	}
}

.header-search {
	background: transparent;
	.header-icons {
		.header-icons-link {
			display: flex;
			margin-bottom: 0;
			li {
				background: #f1f5ff;
				width: 40px;
				height: 40px;
				border-radius: 50px;
				text-align: center;
				line-height: 2.5;
				margin-right: 5px;
			}
			.header-icons-link1,
			.header-icons-link2 {
				width: 2.5rem;
				text-align: center;
				height: 2.5rem;
				font-size: 16px;
				position: relative;
			}
		}
		.header-icons-link1 .main-badge1 {
			position: absolute;
			top: -9px;
			right: -15px;
			text-align: center;
			font-size: 10px;
		}
		.header-icons-link.icons li {
			background: #f1f5ff;
		}
	}
	.header-inputs .input-group-append.searchicon {
		top: 10px;
		right: 25px;
		position: relative;
	}
	.header-search-logo {
		margin-right: 1rem;
	}
	.header-nav .nav-cart .icon-cart {
		i {
			font-size: 18px;
			color: #8c98b9;
		}
		a {
			color: #8c98b9;
		}
	}
}

.header-nav {
	display: flex;
}

.header-links {
	padding: 3px;
	li {
		margin: 5px;
		a {
			padding: 9px 18px;
			border-radius: 3px;
		}
	}
}

.header-main {
	.top-bar .contact {
		margin: 10px 0;
		padding-left: 15px;
		li {
			margin: 0;
		}
	}
	.top-bar-right .custom {
		display: flex;
		margin: 10px 0;
		li {
			margin-right: 15px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
	.ace-responsive-menu li a {
		color: $color;
		&:hover {
			color: $white;
		}
		&:focus {
			color: $black;
		}
	}
	.post-btn {
		float: right;
		margin-left: auto;
	}
}

.header .menu-trigger {
	color: $color;
}

.header-secondary {
	margin: 0 -30px;
	box-shadow: 0 0 30px 0 rgba(115, 77, 191, 0.2);
}

.app-header.header .nav-link.icon i {
	color: #282f3f;
}
.hor-header.header .nav-link.icon i {
	color: #282f3f;
}
.header .form-inline {
	.form-control {
		border: 1px solid $border !important;
		padding-left: 20px;
		width: 400px;
		background:transparent;
		padding-right: 40px;
		&::placeholder {
		color: #a0a3aa;
		}
	}
	.btn {
		border-radius: 0;
		border: 2px solid transparent;
		box-shadow: none;
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;
		background: transparent;
		color: #282f3f;
		margin-left: -60px;
		font-size: 20px;
		line-height:0;
		margin-top: 3px;
	}
}
.header-search{
	margin-left:0.25rem;
	margin-top:3px;
}
.header-right .nav-link.icon {
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 5px;
    padding: 4px 8px;
	border-radius: 50px;
	font-size: 22px;
	background: #f1f5ff;
	&:hover {
		i {
			color: $primary !important;
		}
	}
}

.header-left .nav-link {
	font-size: 15px;
	margin-top: 6.5px;
}

.page-header .page-select {
	width: 20%;
}

.header-menu1 {
	float: none !important;
}

.pri-header {
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	border-bottom: 1px solid $border;
}
.app-header.header {
  .header-notify .dropdown-menu strong, .settings-dropdown .dropdown-menu strong {
    font-weight: 400 !important;
  }
}
.header-right .dropdown-menu{
	min-width:14rem;
}
.header-right .settings-dropdown .dropdown-item, .header-right .drop-profile .dropdown-item{
	padding:.85rem 1.2rem;
}
.header-style .nav-link.icon{
	border:1px solid $white-2 !important;
}
.header-brand-img.color-logo{
	display:none;
}
.header-right .user-details p{
	margin-bottom:0 !important;
}
.header-right .settings-dropdown .dropdown-menu .dropdown-item:hover:last-child,
.header-right .drop-profile .dropdown-menu .dropdown-item:hover:last-child,{
	border-radius:0 0 8px 8px;
}
.header-right .header-notify .dropdown-item.text-center{
	border-radius:0 0 8px 8px;
	border-bottom:0;
}