/*----- Widgets ------*/

.features {
	overflow: hidden;
	h2 {
		font-weight: 600;
		margin-bottom: 12px;
		text-align: center;
		font-size: 2.2em;
	}
	h3 {
		font-size: 20px;
		font-weight: 500;
	}
	span {
		color: #43414e;
		display: block;
		font-weight: 400;
		text-align: center;
	}
}
.col-sm-2 .feature {
	padding: 0;
	border: 0px;
	box-shadow: none;
}

.feature {
	.feature-svg {
		width: 25%;
	}
	.feature-svg3 {
		width: 12%;
	}
	.fea-icon {
		position: relative;
		display: inline-block;
		width: 3rem;
		height: 3rem;
		vertical-align: middle;
		padding-top: 8px;
		border-radius: 50%;
		color: $white;
		i {
			font-size: 1.5rem;
		}
	}
	p {
		margin-bottom: 0;
	}
}


/*----Widgets Styles---*/

.widgets {
	font-size: 35px;
	padding: 20px;
}

.widgetstext {
	top: 0;
	bottom: 0;
	padding: 20px 40px;
}

.widget-line h4 {
	font-size: 24px;
	font-weight: 600;
}

.fs {
	font-size: 25px;
}

.widget-line p {
	font-size: 16px;
}

.widget-line-list li {
	display: inline-block;
	font-size: 16px;
	line-height: 27px;
	padding: 5px 20px 0 15px;
	list-style-type: none;
}

.widgetdate {
	float: right;
	border-radius: 4px;
	background: $white;
	padding: 0 15px;
	p {
		margin-bottom: 0;
		margin-top: 5px;
	}
	h6 {
		font-size: 22px;
		font-weight: 600;
		margin-top: 0;
		margin-bottom: 5px;
	}
}

.user-wideget-text {
	position: relative;
	margin-bottom: -38px;
}

.widget-image img {
	width: 50px;
	height: 50px;
	border: 5px solid $white;
}

.wideget-img {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 1.25rem;
}

.wideget-user-desc {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	.wideget-user-img img {
		border-radius: 100%;
		margin-right: 2rem;
	}
	.user-wrap {
		margin-top: 1.5rem;
	}
}

.wideget-user .wideget-user-icons a {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	margin-top: 0.3rem;
	&:hover {
		color: $white;
	}
}

.wideget-user-info {
	.wideget-user-warap {
		display: flex;
		h4 {
			font-size: 1.5rem;
			font-weight: 500;
			margin-bottom: 0.4rem;
		}
		.wideget-user-warap-r {
			margin-left: 10rem;
		}
	}
	.wideget-user-rating a {
		font-size: 18px;
	}
}

.wideget-user-tab {
	.tab-menu-heading {
		padding: 0;
		border: 0;
		.nav li a {
			color: $color;
			font-size: 15px;
			font-weight: 400;
		}
	}
	.tabs-menu1 ul li a {
		padding: 17px 20px 17px 20px;
	}
}
.widget-spec1 li {
	margin-bottom: 0.5rem;
	font-size: 0.9rem;
}

.widget-spec li {
	margin-bottom: 0.5rem;
	font-size: 0.9rem;
	display: flex;
	&.icon:before {
		content: '\f00c';
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		font-family: fontawesome;
	}
	i {
		margin-right: 0.5rem;
		margin-top: 3px;
	}
}

.wideget-user-tab .tabs-menu1 ul li .active {
	border: 0;
	border-radius: 0;
}

.widget-info-right {
	text-align: right;
	float: right;
}

.ui-widget-header {
	border: 1px solid #2c4359;
	color: #e1e463;
	font-weight: bold;
	a {
		color: #e1e463;
	}
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	border: 1px solid $border;
	font-weight: bold;
	color: #333333;
}

.widgets-cards {
	.wrp {
		&.icon-circle {
			width: 73px;
			height: 73px;
			border-radius: 50%;
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-justify-content: center;
			justify-content: center;
			padding: 0;
			margin-right: 15px;
			i {
				font-size: 1.6rem;
				color: $white;
			}
		}
		p {
			margin-bottom: 0;
			margin-bottom: 10px;
			&:first-child {
				font-weight: 600;
				font-size: 30px;
			}
		}
	}
	.widgets-cards-data {
		margin-top: 8px;
		.wrp p:first-child {
			font-size: 19px;
			font-weight: 600;
		}
	}
}

.widget-user .box-footer {
	padding-top: 3rem;
}

.wideget-user-tab.wideget-user-tab3 .tabs-menu1 ul li {
	a {
		padding: 10px 20px 10px 20px;
		display: block;
		margin: 4px 4px 0 4px;
	}
	&:first-child a {
		margin-left: 0;
	}
	.active {
		border-bottom: 0 !important;
		padding: 10px 20px 12px 20px;
		border-radius: 3px 3px 0 0;
		margin-bottom: -2px;
		background: $white;
		border: 0;
	}
}

@media (max-width: 480px) {
	.wideget-user-tab.wideget-user-tab3 .tabs-menu1 ul li {
		a {
			margin: 0px !important;
		}
		margin: 3px !important;
	}
}

#widgetChart1 {
	width: 114% !important;
	left: -32px;
	position: relative;
	bottom: -20px;
}

.widget-user .widget-user-header {
	padding: 20px;
	height: 120px;
}

.widget-user-header.wideget-bg:before {
	content: '';
	display: block;
	position: absolute;
	background: $black-5;
	width: 100%;
	height: 120px;
	right: 0;
	top: 0;
}

.widget-user {
	.widget-user-username {
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 21px;
		font-weight: 300;
		text-shadow: 0 1px 1px $black-2;
		color: $white;
	}
	.widget-user-desc {
		margin-top: 0;
		color: $white;
	}
	.widget-user-image {
		position: absolute;
		top: 65px;
		left: 50%;
		margin-left: -45px;
		>img {
			width: 90px;
			height: auto;
			border: 3px solid $white;
		}
	}
}

.widget-info {
	i {
		width: 100px;
		height: 100px;
		padding: 27px 0;
		text-align: center;
		border: 2px solid $white;
		border-radius: 50%;
	}
	a {
		border-bottom: 1px solid $white;
	}
}

.widget-user .user-wideget-footer {
	padding-top: 30px;
}

.user-wideget-footer {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-top: 1px solid #f4f4f4;
	padding: 10px;
	background-color: $white;
}

.user-wideget .border-right {
	border-right: 1px solid #f4f4f4;
}