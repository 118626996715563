/*----- Alert ------*/

.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 3px;
	font-size: 0.9375rem;
	&:first-child {
		margin-bottom: 1rem !important;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.alert-heading {
	color: inherit;
}

.alert-link {
	font-weight: 500;
	&:hover,
	a:hover {
		text-decoration: underline;
	}
}

.alert-dismissible {
	padding-right: 3.90625rem;
	.close {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0.75rem 1.25rem;
		color: inherit;
	}
}

.alert-secondary {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
	hr {
		border-top-color: $white-1;
	}
	.alert-link {
		color: #2e3133;
	}
}

.alert-success {
	color: #fcfdff;
	background-color: $success;
	border-color: $success;
	hr {
		border-top-color:  $white-1;
	}
	.alert-link {
		color: #172e00;
	}
}

.alert-info {
	color: #fcfdff;
	background-color: $info;
	border-color: $info;
	hr {
		border-top-color:  $white-1;
	}
	.alert-link {
		color: #193c56;
	}
}

.alert-warning {
	color: #fcfdff;
	background-color: $warning;
	border-color: $warning;
	hr {
		border-top-color:  $white-1;
	}
	.alert-link {
		color: #4d3f05;
	}
}

.alert-danger {
	color: #fcfdff;
	background-color: #f94859;
	border-color: #f94859;
	hr {
		border-top-color:  $white-1;
	}
	.alert-link {
		color: #3f0a09;
	}
}

.alert-light {
	color: #818182;
	background-color: #fefefe;
	border-color: #fdfdfe;
	hr {
		border-top-color: #ececf6;
	}
	.alert-link {
		color: #686868;
	}
}

.alert-dark {
	color: #1b1e21;
	background-color: #d6d8d9;
	border-color: #c6c8ca;
	hr {
		border-top-color: #b9bbbe;
	}
	.alert-link {
		color: #040505;
	}
}

.alert-icon {
	padding-left: 3rem;
	>i {
		color: inherit !important;
		font-size: 1rem;
		position: absolute;
		top: 1rem;
		left: 1rem;
	}
}

.alert-avatar {
	padding-left: 3.75rem;
	.avatar {
		position: absolute;
		top: .5rem;
		left: .75rem;
	}
}

