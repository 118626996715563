h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: 0.66em;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	line-height: 1.1;
}

h1,
.h1 {
	font-size: 2.5rem;
	font-weight: 400;
}

h2,
.h2 {
	font-size: 1.75rem;
}

h3,
.h3 {
	font-size: 1.5rem;
}

h4,
.h4 {
	font-size: 1.125rem;
}

h5,
.h5 {
	font-size: 1rem;
}

h6,
.h6 {
	font-size: 0.875rem;
}
.h100{
	height: 100px !important;
}
.h73{
	height: 73px !important;
}
.h-125{
	height: 125px !important;
}
.h-34{
	height: 34px !important;
}
.h-150{
	height: 150px !important;
}
.h-170{
	height: 170px !important;
}
.h-188{
	height: 188px !important;
}
.h-200{
	height: 200px !important;
}
.h-216{
	height: 216px !important;
}
.h-250{
	height: 250px !important;
}
.h-25 {
	height: 25% !important;
}
.h-50 {
	height: 50% !important;
}
.h-75 {
	height: 75% !important;
}
.h-100 {
	height: 100% !important;
}
.h-100vh {
    height: 100vh !important;
}
.h-auto {
	height: auto !important;
}
.h-0 {
	height: 0 !important;
}
.h-1 {
	height: 0.25rem !important;
}
.h-2 {
	height: 0.5rem !important;
}
.h-3 {
	height: 0.75rem !important;
}
.h-4 {
	height: 1rem !important;
}
.h-5 {
	height: 1.5rem !important;
}
.h-6 {
	height: 2rem !important;
}
.h-7 {
	height: 3rem !important;
}
.h-8 {
	height: 4rem !important;
}
.h-9 {
	height: 6rem !important;
}
.h-120 {
	height: 120px !important;
}
.h-162{
	height: 162px !important;
}
.h-400{
	height: 400px !important;
}
.h-283{
	height:283px !important;
}
.h-292{
	height:292px !important;
}
.h-230{
	height:230px !important;
}
.h-300{
	height: 300px !important;
}
.h-318{
	height: 318px !important;
}
.h-280{
	height: 280px !important
}
.h-260{
	height: 260px !important
}
.h-290{
	height: 290px !important
}
.h-350{
	height: 350px !important
}
.h-390{
	height: 390px !important
}
.h-380{
	height: 380px !important
}