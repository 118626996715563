/*----- Panel ------*/

.panel-body-landing:hover>.table>tbody>tr>td {
	color: $white !important;
}

.panel-heading-landing {
	background: #e8e8ef !important;
	padding: 20px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	border: solid 2px #510fa8 !important;
	border-bottom: none !important;
	text-align: center;
	margin-top: 20px;
}

.col-sm-4 .pricing-table .table td {
	padding: 0.75rem;
	vertical-align: top;
	text-align: left;
}

.panel-heading-landing-box {
	background: #510fa8 !important;
	color: $white !important;
	font-size: 16px !important;
	text-align: center;
	border-top: none !important;
}

.panel-title-landing {
	color: #510fa8 !important;
	font-size: 35px;
	font-weight: bold;
}

.panel-body-landing {
	border: solid 2px #510fa8 !important;
	border-top: none !important;
	border-bottom: none !important;
	text-align: center;
	background: $white;
}

.panel-footer-landing {
	border: solid 2px #510fa8 !important;
	border-bottom-left-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
	border-top: none !important;
	text-align: center;
	background: #e8e8ef;
}


/*------ Pricing Styles01 ---------*/

.panel-heading {
	padding: 10px 15px;
	background: $white;
}

.panel.price>.panel-heading {
	padding: 0;
	position: relative;
}

.price .list-group-item {
	border-bottom: 1px solid rgba(250, 250, 250, 0.5);
}

.panel.price {
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	>.panel-heading {
		-o-transition: all .3s ease;
		-webkit-transition: all .3s ease;
	}
	h3 {
		margin-bottom: 0;
		padding: 20px 0;
	}
	.list-group-item {
		&:last-child {
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
		}
		&:first-child {
			border-top-right-radius: 0px;
			border-top-left-radius: 0px;
		}
	}
	margin-bottom: 1.5rem;
	box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
	overflow: hidden;
}

.panel-body {
	padding: 15px;
	background: $white;
	.lead {
		strong {
			font-size: 40px;
			margin-bottom: 0;
			font-weight: 400;
		}
		font-size: 20px;
		margin-bottom: 0;
		padding: 10px 0;
	}
}

.panel-footer {
	padding: 10px 15px;
	background-color: #e8e8ef;
	border-top: 1px solid $border;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

.panel-group .panel {
	border-radius: 0;
	box-shadow: none;
	border-color: #eff2f7;
}

.panel-default>.panel-heading {
	padding: 0;
	border-radius: 0;
	background-color: $white;
	border-color: #eff2f7;
}
#accordion1 .panel-title {
	border:1px solid $border;
	border-bottom:0;
	border-radius:5px 5px 0 0;
}
.panel-title {
	border:1px solid $border;
	border-radius:5px;
	margin-bottom: 0;
	>a {
		display: block;
		padding: 15px;
		text-decoration: none;
		font-size: 15px;
	}
}
.panel-default>.panel-heading+.panel-collapse>.panel-body {
	border: 1px solid $border;
    border-radius: 0 0 5px 5px;
}

.panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: $white;
	background: none;
	box-shadow: none;
	&:last-child {
		border-bottom: none;
	}
}

.panel-group1 {
	padding: 1.5rem;
	>.panel1:first-child .panel-heading1 {
		border-radius: 4px 4px 0 0;
	}
	.panel1 {
		border-radius: 0;
	}
}

.panel-body1 {
	padding: 10px;
	background: $white;
}

.panel-title1 {
	margin-bottom: 0;
}

.panel-group1 .panel1+.panel1 {
	margin-top: 0;
}
.panel-group1 .panel-body {
	border-top: 1px solid $border;
}

.panel-title1 a {
	display: block;
	color: $white;
	padding: 15px;
	position: relative;
	font-weight: 400;
	font-size: 15px;
	&.collapsed {
		color: $white;
		font-size: 15px;
	}
}

.panel1:last-child {
	.panel-body1 {
		border-radius: 0 0 4px 4px;
	}
	.panel-heading1 {
		border-radius: 0 0 4px 4px;
		transition: border-radius 0.3s linear 0.2s;
		&.active {
			border-radius: 0;
			transition: border-radius linear 0s;
		}
	}
}

.panel-title a.accordion-toggle {
	&:before {
		content: "\f068";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
	&.collapsed:before {
		content: "\f067";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
}

.panel-heading1 {
	background-color: $white;
	border-radius: 0;
	border: none;
	color: $color;
	padding: 0;
	border-radius:4px;
	a {
		&.collapsed:before {
			content: "\e9af";
			position: absolute;
			font-family: 'feather' !important;
			right: 10px;
			top: 12px;
			font-size: 20px;
			transition: all 0.5s;
			transform: scale(1);
		}
		&:before {
			content: "\e994";
			position: absolute;
			font-family: 'feather' !important;
			right: 10px;
			top: 12px;
			font-size: 20px;
			transition: all 0.5s;
			transform: scale(1);
		}
	}
	&.active a:before {
		content: ' ';
		transition: all 0.5s;
		transform: scale(0);
	}
}

#bs-collapse .panel-heading1 {
	a:after {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: 'FontAwesome';
		right: 5px;
		top: 10px;
		transform: scale(0);
		transition: all 0.5s;
	}
	&.active a:after {
		content: "\f01a";
		transform: scale(1);
		transition: all 0.5s;
	}
}

.panel-primary .list-group-item.active {
	color: #FFF;
	background-color: #1753fc;
	border-color: #1753fc;
	&:hover {
		color: #FFF;
		background-color: #6c4cbb;
		border-color: #6c4cbb;
	}
}

.panel-success .list-group-item.active {
	color: $white;
	background-color: #2fd8c6;
	border-color: #2fd8c6;
	&:hover {
		color: $white;
		background-color: #4b9400;
		border-color: #4b9400;
	}
}

.panel-info .list-group-item.active {
	color: $white;
	background-color: #28afd0;
	border-color: #28afd0;
	&:hover {
		color: $white;
		background-color: #3ec7e8;
		border-color: #3ec7e8;
	}
}

.panel-warning .list-group-item.active {
	color: $white;
	background-color: #fdb901;
	border-color: #fdb901;
	&:hover {
		color: $white;
		background-color: #cea70c;
		border-color: #cea70c;
	}
}

.panel-danger .list-group-item.active {
	color: $white;
	background-color: #ec2d38;
	border-color: #ec2d38;
	&:hover {
		color: $white;
		background-color: #d6434c;
		border-color: #d6434c;
	}
}

.panel-default .list-group-item.active {
	color: #2e384d;
	background-color: #f4f3f9;
	border-color: rgba(107, 122, 144, 0.1);
	&:hover,
	&:focus {
		color: #2e384d;
		background-color: #f4f3f9;
		border-color: #f4f3f9;
	}
}

.expanel-default {
	border: #ddd !important;
}

.expanel-secondary {
	border: $secondary !important;
}

.expanel-success {
	border: $success !important;
}

.expanel-danger {
	border: #f94859 !important;
}

.expanel-secondary>.expanel-heading {
	color: $white !important;
	background-color: $secondary !important;
	border-color: $secondary !important;
}

.expanel-success>.expanel-heading {
	color: $white !important;
	background-color: $success !important;
	border-color: $success !important;
}

.expanel-danger>.expanel-heading {
	color: $white !important;
	background-color: $danger !important;
	border-color: $danger !important;
}

.expanel-info>.expanel-heading {
	color: $white !important;
	background-color: $info !important;
	border-color: $info !important;
}

.expanel-warning>.expanel-heading {
	color: $black !important;
	background-color: $warning !important;
	border-color: $warning !important;
}

.expanel-title {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	font-size: 16px !important;
	color: inherit !important;
}

.expanel {
	margin-bottom: 20px !important;
	background-color: $white !important;
	border: 1px solid $border !important;
	border-radius: 4px !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.expanel-default>.expanel-heading {
	background-color: #f4f7fe !important;
	border-color: 1px solid $border !important;
}

.expanel-heading {
	padding: 10px 15px !important;
	border-bottom: 1px solid $border;
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}

.expanel-footer {
	padding: 10px 15px !important;
	background-color: #f4f7fe !important;
	border-top: 1px solid $border !important;
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}

.expanel-body {
	padding: 15px !important;
}

.panel-heading1.bg-primary {
	.panel-title1 a {
		&.collapsed {
			color: $white;
			margin-bottom: 16px;
		}
		display: block;
	}
}