#chat-popup {
  color: #fff;
  position: fixed;
  bottom: -100px;
  right: 20px;
  z-index: 99;
  display: block;
  text-align: center;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  -o-border-radius: 2px;
  z-index: 1;
  height: 50px;
  width: 50px;
  line-height: 59px;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color .1s linear;
  -moz-transition: background-color .1s linear;
  -webkit-transition: background-color .1s linear;
  -o-transition: background-color .1s linear;
  background: $secondary;
  transition: all 1s;

  i {
    color: #fff;
    font-size: 20px;
  }

  &.chat-popup-active {
    bottom: 80px;
    transition: all 1s;
  }
}

.chat-message-popup {
  background-color: #f1f5ff;
  bottom: -550px;
  height: 500px;
  position: fixed;
  right: 20px;
  width: 370px;
  z-index: 99999;
  box-shadow: 0 2px 17px 2px rgba(113, 103, 255, 0.27);
  overflow: hidden;
  transition: all 1s;

  &.card-fullscreen {
    height: 500px;
    position: fixed;
    right: 20px;
    width: 370px;
    z-index: 99999;
    top: 100vh;
    transition: none;
  }

  &.active.card-fullscreen {
    display: block;
    width: 100%;
    height: 100vh;
    top: 0;
    transition: none;
    transition: none;
  }
}

.popup-minimize-fullscreen {
  display: none;
}

.card-fullscreen {
  .popup-minimize-fullscreen {
    display: inline-block;
  }

  .popup-minimize {
    display: none;
  }
}

.chat-message-popup {
  &.card-fullscreen {
    .popup-messages {
      max-height: 80vh;
    }

    .popup-messages-footer > textarea {
      width: 99% !important;
    }
  }

  &.active {
    display: block;
    bottom: 0;
    transition: all 1s;
	border-radius:8px 8px 0 0px
  }

  .popup-messages {
    max-height: 320px;
    overflow: auto;

    /* background: #f0edfb; */
    margin-top: 14px;
    padding: 20px;
    padding-top: 0;
  }

  .user-header {
    box-shadow: 0 0px 14px 0px rgba(130, 130, 134, 0.1);
    background: #fff;
  }

  .dropdown {
    z-index: 99;
  }
}

.popup-box {
  .popup-head .popup-head-right {
    margin: 7px 7px 0;
  }

  .popup-messages {}
}

.popup-head-left img {
  border-radius: 50%;
  width: 44px;
}

.popup-messages-footer {
  > textarea {
    height: 42px !important;
    margin: 7px;
    padding: 5px 15px !important;
    width: 95% !important;
    line-height: 30px;

    &::placeholder {
      color: #bfbfc3;
    }
  }

  background: #fff none repeat scroll 0 0;
  bottom: 0;
  position: absolute;
  width: 100%;

  .btn-footer {
    overflow: hidden;
    padding: 2px 5px 10px 6px;
    width: 100%;
  }
}

.simple_round {
  background: #d1d1d1 none repeat scroll 0 0;
  border-radius: 50%;
  color: #4b4b4b !important;
  height: 21px;
  padding: 0 0 0 1px;
  width: 21px;
}

.popup-box .popup-messages {
  background: #fff none repeat scroll 0 0;
  height: 275px;
  overflow: auto;
}

.direct-chat-messages {
  /*overflow: auto;*/
  transform: translate(0px, 0px);
}

.popup-messages {
  .chat-box-single-line {
    height: 12px;
    margin: 7px 0 20px;
    position: relative;
    text-align: center;
  }

  abbr.timestamp {
    padding: 3px 14px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
  }
}

.popup-head-right .btn-group {
  display: inline-flex;
  margin: 0 8px 0 0;
  vertical-align: top !important;
}

.chat-header-button {
  background: transparent none repeat scroll 0 0;
  border: 1px solid transparent;
  border-radius: 50%;
  font-size: 14px;
  height: 30px;
  width: 30px;
  color: #fff;
  cursor: pointer;
}

.popup-messages-footer .btn-footer button {
  cursor: pointer;
}

.popup-head-right .btn-group .dropdown-menu {
  border: medium none;
  min-width: 122px;
  padding: 0;

  li a {
    font-size: 12px;
    padding: 3px 10px;
    color: #303030;
  }
}

.popup-messages {
  .direct-chat-messages {
    height: auto;
    width: 100%;
  }

  .direct-chat-text {
    background: #dfece7 none repeat scroll 0 0;
    border: 1px solid #dfece7;
    border-radius: 2px;
    color: #1f2121;
  }

  .direct-chat-timestamp {
    color: #68686b;
    opacity: 0.6;
  }

  .direct-chat-name {
    font-size: 15px;
    font-weight: 600;
    color: #6e6e75;
    opacity: 0.9;
  }

  .direct-chat-info {
    display: block;
    font-size: 12px;
    margin-bottom: 0;
  }

  .big-round {
    margin: -9px 0 0 !important;
  }

  .direct-chat-img {
    border-radius: 50%;
    float: left;
    height: 35px;
    width: 35px;
  }

  .right .direct-chat-img {
    float: right;
  }

  .direct-chat-reply-name {
    color: #d8dede;
    font-size: 15px;
    margin: 0 0 0 10px;
    opacity: 0.9;
  }

  .direct-chat-img-reply-small {
    border: 1px solid #e6eaea;
    border-radius: 50%;
    float: left;
    height: 20px;
    margin: 0 8px;
    width: 20px;
    background: #3f9684;
  }

  .direct-chat-msg {
    margin-bottom: 10px;
    position: relative;
  }
  .direct-chat-body{
	margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   }
   .right .direct-chat-body{
		align-items: flex-end;
		margin-right: 10px;
		margin-left: 0px;
   }
  .doted-border::after {
    background: transparent none repeat scroll 0 0 !important;
    bottom: 0;
    content: "";
    left: 17px;
    margin: 0;
    position: absolute;
    top: 0;
    width: 2px;
    display: inline;
    z-index: -2;
  }

  .direct-chat-msg::after {
    border-right: medium none;
    bottom: 0;
    content: "";
    left: 17px;
    margin: 0;
    position: absolute;
    top: 0;
    width: 2px;
    display: inline;
    z-index: -2;
  }

  .direct-chat-text {
    &::before {
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 12px 12px 0;
      border-color: transparent #dfe8ff transparent transparent;
      position: absolute;
      left: -13px;
      top: -1px;
      content: "";
    }

    &::after {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 0 100% 0 0;
      left: -15px;
      top: -1px;
      position: absolute;
    }
  }
  .direct-chat-text:not(:first-child)::before, .right .direct-chat-text:not(:first-child)::before, .direct-chat-text:not(:first-child)::after, .right .direct-chat-text:not(:first-child)::after {
	display: none;
  }
  .direct-chat-text:not(:first-child), .right .direct-chat-text:not(:first-child){
	border-radius:5px;
  }
  .right .direct-chat-text {
    &::before {
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 12px 12px 0;
      border-color: transparent #ffffff transparent transparent;
      position: absolute;
      right: -13px;
      top: -1px;
      left: auto;
      content: "";
      transform: rotate(-90deg);
    }

    &::after {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 0 100% 0 0;
      left: auto;
      right: -13px;
      top: -1px;
      position: absolute;
      transform: rotate(-90deg);
    }
  }

  .direct-chat-text {
    border-radius: 2px;
    color: #1f2121;
    background: #dfe8ff    none repeat scroll 0 0;
    border: 1px solid #dfe8ff ;
    border-radius: 0 5px 5px 5px;
    padding: 10px 10px 5px 10px;
    box-shadow: 0 2px 3px 0px rgba(41, 58, 93, 0.3);
    margin-bottom: 8px;
    position: relative;
    margin-left: 8px;
  }
}

.chat-message-popup .popup-head {
  display: block;
  padding: 15px 20px;
  font-weight: 700;
  background: $primary;
  color: #fff;
  font-size: 18px;
}

.time-text {
  font-size: 10px;
  opacity: 0.5;
  margin-left: 9px;
  float: right;
  margin-top: 15px;
}

.time-text-left {
  font-size: 10px;
  opacity: 0.5;
  margin-right: 9px;
  float: left;
  margin-top: 10px;
}

.direct-chat-msg.right {
  flex-direction: row-reverse;

  .direct-chat-text {
    margin-right: 13px;
    margin-left: 0;
    background: #ffffff;
    border-color: #ffffff;
    /* color:#fff; */
    border-radius: 5px 0 5px 5px;
  }
}

.chat-footer-icons {
  position: absolute;
  bottom: 13px;
  right: 22px;

  a {
    padding: 5px 7px;
    font-size: 20px;
    line-height: 18px;

    i {
      transform: rotate(45deg);
      display: inline-block;
    }
  }
}

.chat-message-header-icons a {
  width: 34px;
  height: 34px;
  display: inline-block;
  background: #f1f5ff;
  line-height: 36px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50%;
  border: 1px solid $border;
}

.message-popup-left {
  position: absolute;
  top: 18px;
  left: 20px;
}

.message-popup-right {
  position: absolute;
  top: 18px;
  right: 20px;
}

.more-images {
  position: absolute;
  width: 4rem;
  height: 4rem;
  top: 0;
  border: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0.25rem;
  text-align: center;
  line-height: 4rem;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  cursor: pointer;
  border-radius: 2px;
  font-weight: 600;
}

/** -- popup-chat -- **/