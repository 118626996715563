/*----- Lists ------*/

.list-inline-dots .list-inline-item+.list-inline-item:before {
	content: '· ';
	margin-left: -2px;
	margin-right: 3px;
}

.list-separated-item {
	padding: 1rem 0;
	&:first-child {
		padding-top: 0;
	}
}

.listorder {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: $white;
	border: 1px solid $border;
	list-style-type: decimal;
	list-style-position: inside;
}

.listorder1 {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: $white;
	border: 1px solid $border;
	list-style-type: upper-alpha;
	list-style-position: inside;
}

.listunorder {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: $white;
	border: 1px solid $border;
	list-style-type: circle;
	list-style-position: inside;
}

.listunorder1 {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: $white;
	border: 1px solid $border;
	list-style-type: square;
	list-style-position: inside;
}
.list-style-cricle {
	list-style-type: circle;
	padding-left: 40px;
}
.list-style-square {
	list-style-type: square;
	padding-left: 40px;
}
.list-style-uproman {
	list-style-type: upper-roman;
	padding-left: 40px;
}
.list-style-lwalpha {
	list-style-type: lower-alpha;
	padding-left: 40px;
}
.list-style-disc {
	list-style-type: disc;
	padding-left: 40px;
}
ol.list-order-style {
	margin-bottom: 0;
}
.inline-list li {
	display: inline-block;
	margin-right: 15px;
  
	&:last-child {
	  margin-right: 0;
	}
}