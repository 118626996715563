/*----- Carousel ------*/

.carousel-indicators {
	li {
		&.active {
			color: $primary;
			background: $primary !important;
			border: 5px double;
		}
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		border-radius: 50%;
		background-color: 5;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	position: absolute;
	right: 0;
	bottom: 10px;
	left: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
	.active {
		background-color: $white;
	}
}

.carousel-caption {
	position: absolute;
	right: 15%;
	top: 30%;
	left: 15%;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: $white;
	text-align: center;
}

.carousel-indicators1 {
	&.carousel-indicators {
		position: absolute;
		right: 0;
		left: auto;
		top: 0;
		z-index: 15;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		padding-left: 0;
		margin-top: 10px;
		list-style: none;
		margin: 12px 19px;
	}
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: 5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-indicators2 {
	&.carousel-indicators {
		position: absolute;
		right: auto;
		left: 0;
		z-index: 15;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		padding-left: 0;
		margin-top: 10px;
		list-style: none;
		margin: 12px 20px;
		top: 0;
	}
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: 5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-indicators3 {
	&.carousel-indicators {
		position: absolute;
		right: 0;
		left: auto;
		bottom: 0;
		z-index: 15;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		padding-left: 0;
		margin-top: 10%;
		list-style: none;
		margin: 12px 19px;
	}
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: 5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-indicators4 {
	&.carousel-indicators {
		position: absolute;
		right: auto;
		left: 0;
		z-index: 15;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		padding-left: 0;
		margin-top: 10px;
		list-style: none;
		margin: 12px 20px;
		bottom: 0;
	}
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: 5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-item-background {
	content: '';
	background: $black-5;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.carousel-inner>.item> {
	img,
	a>img {
		width: 100%;
	}
}

.carousel-bg {
	.prev-icon,
	.next-icon {
		background-color: $black-7;
		padding: 5px 15px;
		border-radius: 100px;
		font-size: 20px;
	}
}

.carousel-control {
	&:hover,
	&:focus,
	&:active {
		color: #333;
	}
}

.carousel-caption,
.carousel-control .fa {
	font: normal normal normal 30px/26px FontAwesome;
}

.thumbcarousel {
	.carousel-control-prev {
		left: 0;
		background: $black-5;
	}
	.carousel-control-next {
		right: 0;
		background: $black-5;
	}
}

.carousel .carousel-indicators {
	bottom: 0;
}

.carousel .carousel-control {
	height: 40px;
	width: 40px;
	background: none;
	margin: auto 0;
	border-radius: 50%;
	background: $black-6;
	z-index: 999;
	i {
		font-size: 32px;
		position: absolute;
		top: 1.6rem;
		display: inline-block;
		margin: -18px 0 0 0;
		z-index: 5;
		left: 0;
		right: 0;
		color: $white-9;
		text-shadow: none;
		font-weight: bold;
		z-index: 999;
	}
}

.carousel-control-next {
	right: -1.8rem;
}

.carousel-control-prev {
	left: -1.8rem;
}

.product-slider {
	.carousel-control-next i,
	.carousel-control-prev i {
		font-size: 2rem;
		padding: 0.5rem;
	}
}

.test-carousel {
	.carousel-control-next i,
	.carousel-control-prev i {
		font-size: 2rem;
		color: $black;
		padding: 0.5rem;
	}
}

.owl-carousel {
	position: relative;
	.owl-item {
		position: relative;
		cursor: url(../images/other/cursor.png), move;
		overflow: hidden;
	}
	.owl-dots {
		margin: 0 auto;
		text-align: center;
	}
	button.owl-dot {
		margin: 10px 10px 0 10px;
		border-radius: 50%;
		width: 10px;
		height: 10px;
		text-align: center;
		display: inline-block;
		border: none;
	}
}

.owl-carousel-icons5 .owl-nav {
	.owl-prev {
		position: absolute;
		top: 42%;
		left: auto;
		right: -13px;
		margin-top: -1.65em;
	}
	.owl-next {
		position: absolute;
		top: 58%;
		left: auto;
		right: -13px;
		margin-top: -1.65em;
	}
}

.owl-carousel-icons4.owl-carousel .owl-item img {
	margin: 0 auto;
}

#carousel-controls.owl-carousel .owl-item img {
	width: 100%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: no-repeat 50% / 100% 100%;
}

.dashboard-carousel {
	.carousel-control-prev .carousel-control-prev-icon,
	.carousel-control-next .carousel-control-next-icon {
		background-image: none;
	}
	.carousel-control-next .carousel-control-next-icon::after {
		font-family: "Material Design Icons";
		content: '\F142';
		color: #3827b4;
		font-weight: bold;
		font-size: 0.812rem;
		display: block;
		line-height: 1.35rem;
	}
	.carousel-control-prev {
		.carousel-control-prev-icon::after {
			font-family: "Material Design Icons";
			content: '\F141';
			display: block;
			font-size: 0.812rem;
			color: #3827b4;
			font-weight: bold;
			line-height: 1.35rem;
			margin-right: .1rem;
		}
		&.control-light {
			.carousel-control-prev-icon::after {
				color: #e4e9f9;
			}
			border-color: #e4e9f9;
		}
	}
	.carousel-control-next.control-light {
		border-color: #e4e9f9;
	}
	.carousel-control-prev,
	.carousel-control-next {
		background-color: $background;
		border: 1px solid #e4e9f9;
		width: 1.625rem;
		height: 1.625rem;
		top: 1.062rem;
		border-radius: 0.375rem;
		opacity: 1;
	}
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.dashboard-carousel .carousel-control-prev {
	right: 4rem;
	left: auto;
}

.dashboard-carousel .carousel-control-next {
	right: 1.8rem;
}

.carousel-indicators li {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	margin: 1px 2px;
	background: #e2e2e2;
	border: 4px solid $white;
	&.active {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		margin: 1px 2px;
	}
}

.banner1 {
	.carousel-inner img {
		width: 100%;
		max-height: 460px;
	}
	.carousel-item:before {
		content: '';
		display: block;
		position: absolute;
		background: $black-6;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
	.carousel-control {
		width: 0;
		&.left,
		&.right {
			opacity: 1;
			filter: alpha(opacity=100);
			background-image: none;
			background-repeat: no-repeat;
			text-shadow: none;
		}
		&.left span,
		&.right span {
			padding: 15px;
		}
		.glyphicon-chevron-left,
		.glyphicon-chevron-right,
		.icon-prev,
		.icon-next {
			position: absolute;
			top: 45%;
			z-index: 5;
			display: inline-block;
		}
		.glyphicon-chevron-left,
		.icon-prev {
			left: 0;
		}
		.glyphicon-chevron-right,
		.icon-next {
			right: 0;
		}
		&.left span,
		&.right span {
			background: $white-1;
			color: $white;
		}
		&.left span:hover,
		&.right span:hover {
			background: $white-3;
		}
	}
}