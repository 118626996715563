/*----- list-group ------*/

.list-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
}

.list-group-item-action {
	width: 100%;
	color: $color;
	text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
	color: $color;
	text-decoration: none;
	background-color: #fafaff;
}

.list-group-item-action:active {
	color: $color;
	background-color: #fafaff;
}

.list-group-item {
	position: relative;
	display: block;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: $white;
	border: 1px solid $border;
}

.list-group-item:first-child {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.list-group-item:last-child {
	margin-bottom: 0;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

.list-group-item:hover,
.list-group-item:focus {
	z-index: 1;
	text-decoration: none;
	color: $primary !important;
	background: #f1f5ff!important;
}

.list-group-item.disabled,
.list-group-item:disabled {
	color: #7c90b1;
	background-color: $white;
}

.list-group-item.active {
	z-index: 2;
	background-color: $background;
	border: 1px solid $border;
	color: $color;
}

.list-group-flush .list-group-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
	border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
	border-bottom: 0;
}

.list-group-item-primary {
	color: #24426c;
	background-color: #cbdbf2;
}

.list-group-flush .list-group-item:hover {
	background: #f4f7fe;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
	color: #24426c !important;
	background-color: #b7cded !important;
}

.list-group-item-primary.list-group-item-action.active {
	color: $white;
	background-color: #24426c;
	border-color: #24426c;
}

.list-group-item-secondary {
	color: #464a4e;
	background-color: #dddfe2;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
	color: #464a4e;
	background-color: #cfd2d6;
}

.list-group-item-secondary.list-group-item-action.active {
	color: $white;
	background-color: #464a4e;
	border-color: #464a4e;
}

.list-group-item-success {
	color: #008048;
	background-color: #ccffe9;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
	color: #316100;
	background-color: #c5e7a4;
}

.list-group-item-success.list-group-item-action.active {
	color: $white;
	background-color: #316100;
	border-color: #316100;
}

.list-group-item-info {
	color: $info;
	background-color: #e6f1ff;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
	color: $info;
	background-color: #b3dcf9;
}

.list-group-item-info.list-group-item-action.active {
	color: $white;
	background-color: $info;
	border-color: $info;
}

.list-group-item-warning {
	color: $warning;
	background-color: #ffe9cc;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
	color: $warning;
	background-color: #ffe9cc;
}

.list-group-item-warning.list-group-item-action.active {
	color: $white;
	background-color: $warning;
	border-color: $warning;
}

.list-group-item-danger {
	color: #f94859;
	background-color: #ffcfcc;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
	color: #f94859;
	background-color: #ffcfcc;
}

.list-group-item-danger.list-group-item-action.active {
	color: $white;
	background-color: #f94859;
	border-color: #f94859;
}

.list-group-item-light {
	color: #818182;
	background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
	color: #818182;
	background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
	color: $white;
	background-color: #818182;
	border-color: #818182;
}

.list-group-item-dark {
	color: #1b1e21;
	background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
	color: #1b1e21;
	background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
	color: $white;
	background-color: #1b1e21;
	border-color: #1b1e21;
}

.list-group-item.active .icon {
	color: inherit !important;
}

.list-group-transparent .list-group-item {
	background: none;
	border: 0;
	padding: .5rem 1rem;
}

.list-group-transparent .list-group-item.active {
	background: #f1f5ff;
	font-weight: 600;
	color:$primary;
}

.list-group-item.products {
	display: inline-flex;
	width: 100%;
}

.list-group-item.products .price h4 {
	font-size: 18px;
	margin-bottom: 2px;
}

.list-group-item.products .rated-products-ratings {
	font-size: 14px;
}

.list-group.lg-alt .list-group-item {
	border: 0;
}