/*----- Typography ------*/

.heading-inverse {
	background-color: #333;
	color: $white;
	padding: 5px;
}

.heading-success {
	background-color: $success;
	color: $white;
	padding: 5px;
}

.heading-info {
	background-color: #0a7ffb;
	color: $white;
	padding: 5px;
}

.heading-warning {
	background-color: $warning;
	color: $white;
	padding: 5px;
}

.heading-danger {
	background-color: #f94859;
	color: $white;
	padding: 5px;
}

.text-monospace {
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
	text-align: justify !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-white {
	color: $white !important;
}

.text-secondary {
	color: $secondary !important;
}

a.text-secondary {
	&:hover,
	&:focus {
		color: $secondary !important;
	}
}

.text-success {
	color: $success !important;
}

a.text-success {
	&:hover,
	&:focus {
		color: #92cc43 !important;
	}
}

.text-info {
	color: #0ca7ec !important;
}

a.text-info {
	&:hover,
	&:focus {
		color: #1594ef !important;
	}
}

.text-warning {
	color: $warning !important;
}

a.text-warning {
	&:hover,
	&:focus {
		color: #ffb113 !important;
	}
}

.text-danger {
	color: #ff0000 !important;
}

a.text-danger {
	&:hover,
	&:focus {
		color: #fb5b50 !important;
	}
}

.text-light {
	color: #f8f9fa !important;
}

a.text-light {
	&:hover,
	&:focus {
		color: #dae0e5 !important;
	}
}

.text-dark {
	color: #504e70 !important;
}

.text-body {
	color: #3d4e67 !important;
}

.text-muted {
	color: #616267 !important;
}

.text-black-50 {
	color: $black-5 !important;
}


.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.text-wrap {
	font-size: 14px;
	line-height: 1.66;
	> {
		 :first-child {
			margin-top: 0;
		}
		 :last-child {
			margin-bottom: 0;
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-top: 1em;
		}
	}
}

.text-inherit {
	color: inherit !important;
}

.text-default {
	color: $color !important;
}

.text-muted-dark {
	color: #5f6877 !important;
}

.text-blue {
	color: $black !important;
}

.text-indigo {
	color: #6574cd !important;
}

.text-purple {
	color: $purple !important;
}

.text-lightpink-red {
	color: #ff7088 !important;
}

.text-lightgreen {
	color: #26eda2 !important;
}

.text-pink {
	color: #ff2b88 !important;
}

.text-red {
	color: #ff0000 !important;
}

.text-green {
	color: $success !important;
}

.text-teal {
	color: $teal !important;
}

.text-cyan {
	color: $cyan !important;
}

.text-gray {
	color: #858d97 !important;
}

.text-gray-dark {
	color: #3d3f4e !important;
}

.text-azure {
	color: #0a7ffb !important;
}

.text-lime {
	color: #bfff00 !important;
}

textarea[cols] {
	height: auto;
}

.richText {
	.richText-toolbar {
		ul {
			li a {
				border-right: $border solid 1px;
			}
			background: #f4f7fe;
		}
		background: #f4f7fe;
	}
	border: $border solid 1px;
	background-color: #f4f7fe;
}
h1.animated-text {
	font-family: monospace;
	/* Web-safe typewriter-like font */
	overflow: hidden;
	/* Ensures the content is not revealed until the animation */
	border-right: .15em solid orange;
	/* The typwriter cursor */
	white-space: nowrap;
	/* Keeps the content on a single line */
	margin: 0 auto;
	/* Gives that scrolling effect as the typing happens */
	letter-spacing: .15em;
	/* Adjust as needed */
	animation: typing 3.5s steps(30, end), blinking-cursor 0.5s step-end infinite;
}

.banner1 {
	.header-text {
		position: absolute;
		left: 0;
		right: 0;
		color: $white;
	}
	.slider .header-text {
		top: 29%;
	}
	.header-text {
		h2 {
			font-size: 40px;
		}
		h3 {
			font-size: 25px;
		}
		h2 span {
			padding: 10px;
		}
		h3 span {
			padding: 15px;
		}
	}
}

.text-primary-light {
	color: #7359ff !important;
}

/*////////////////////Dropshadows//////////////////*/

.primary-dropshadow {
	box-shadow: 0 5px 10px rgba(66, 91, 214, 0.3);
}

.secondary-dropshadow {
	box-shadow: 0 5px 10px rgba(2, 195, 238, 0.3);
}

.info-dropshadow {
	box-shadow: 0 5px 10px rgba(26, 168, 199, 0.3);
}

.success-dropshadow {
	box-shadow: 0 5px 10px rgba(4, 179, 114, 0.3);
}

.warning-dropshadow {
	box-shadow: 0 5px 10px rgba(242, 159, 3, 0.3);
}

.danger-dropshadow {
	box-shadow: 0 5px 10px rgba(249, 33, 54, 0.3);
}

.default-shadow {
	box-shadow: 4px 3px 6px 0 $black-2;
}


/*////////////////////text-shadow//////////////////*/

.text-primary-shadow {
	text-shadow: -2px 2px 2px rgba(34, 5, 191, 0.3);
}

.text-secondary-shadow {
	text-shadow: -2px 2px 2px rgba(245, 128, 84, 0.3);
}

.text-info-shadow {
	text-shadow: -2px 2px 2px rgba(12, 167, 23, 0.3);
}

.text-success-shadow {
	text-shadow: -2px 2px 2px rgba(54, 179, 126, 0.3);
}

.text-warning-shadow {
	text-shadow: -2px 2px 2px rgba(255, 171, 0, 0.3);
}

.text-danger-shadow {
	text-shadow: -2px 2px 2px rgba(255, 0, 0, 0.3);
}

.text-purple-shadow {
	text-shadow: -2px 2px 2px rgba(99, 2, 143, 0.3);
}

.text-pink-shadow {
	text-shadow: -2px 2px 2px rgba(255, 43, 136, 0.3);
}


.text-facebook{
	color:#2150bc;
}
.text-google{
	color:#ff3d31;
}
.text-instagram{
	color:#fe024f;
}
.text-twitter{
	color:#079fe8;
}